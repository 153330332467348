import React, { useCallback, useContext, useMemo } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { HamburgerSlider as Hamburger } from 'react-animated-burgers';
import { useToggle } from 'usehooks-ts';
import classNames from 'classnames';

import companyLogo from 'src/assets/images/dentons-logo.png';
import globalSustainabilityNetworkLogo from 'src/assets/images/global-sustainability-network.png';
import { useViewport } from 'src/hooks';
import { AccountCircle } from 'src/assets/icons';
import { capitalize } from 'src/helpers';
import { AuthContext, MobileNavScreenContext } from 'src/providers';
import { adminRoutes, links } from 'src/routes/header-links';

import { UserDropdown } from './components';

import './Header.scss';

const companyName = "Dentons";

const MobileContent = () => {
  const { isDesktop } = useViewport();
  const location = useLocation();
  const history = useHistory();
  const [config, setConfig] = useContext(MobileNavScreenContext);

  const toggleMobileNavScreen = useCallback((state?: boolean) => {
    const isOpen = typeof state === 'boolean' ? state : !config.isOpen;
    setConfig({ isOpen });
  }, [config.isOpen, setConfig]);

  history.listen(() => {
    toggleMobileNavScreen(false);
  });

  const currentTab = useMemo(() => capitalize(location.pathname.slice(1)) as string, [location.pathname])

  return <>
    <Hamburger barColor="white" isActive={config.isOpen} toggleButton={toggleMobileNavScreen} />
    <div className="header__active-tab-name">{currentTab}</div>
    <div className={classNames("user-avatar",{
      ['user-avatar__mobile']: !isDesktop,
    })}>
      <AccountCircle />
    </div>
  </>
};

const DesktopContent = () => {
  const [showDropdown, toggleDropdown] = useToggle(false);
  const { isAdmin } = useContext(AuthContext);

  return <>
    <img className="company-logo" src={companyLogo} alt={companyName} />
    <nav className="navbar">
      {links
        .filter(({ to }) => isAdmin ? true : !adminRoutes.includes(to))
        .map(({ to, label}, i) => <NavLink key={i} className="navlink" activeClassName="navlink--active" to={to}>{label}</NavLink>)}
    </nav>
    <div className="header__right-side">
      <img className="logo" src={globalSustainabilityNetworkLogo} alt="Global Sustainability Network" />
      <div className="user-avatar">
        <div className="user-avatar__icon" onClick={toggleDropdown}>
          <AccountCircle />
        </div>
        {showDropdown && <UserDropdown className="user-avatar__user-dropdown" toggleDropdown={toggleDropdown} />}
      </div>
    </div>
  </>;
};

export const Header: React.FC = () => {
  const { isDesktop } = useViewport();

  return (
    <header className={classNames('header', {
      ['header__mobile']: !isDesktop,
    })}>
      {isDesktop ? <DesktopContent /> : <MobileContent />}
    </header>
  );
};
