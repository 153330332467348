import React, { useContext } from 'react';
import { ConfirmContext, ConfirmContextValue } from 'src/providers';

export const useConfirm = () => {
  const [confirmationConfig, setConfirmationConfig] = useContext(ConfirmContext);

  const confirm = (
    message: string | React.ReactElement,
    {
      confirmButtonLabel = 'Confirm',
      closeButtonLabel = 'Cancel',
      icon = null,
      hasFooterDivider = true,
      buttonLayout = 'horizontal',
      closeOnOverlayClick = false,
      isTitleCenteredVertical = false,
      fullWidthButton = false,
      onSecondaryButtonClick,
    }: Partial<ConfirmContextValue> = {},
  ) => {
    if (confirmationConfig.isOpen) {
      return Promise.reject('Another instance of confirmation modal already opened');
    }

    const promise = new Promise((resolve, reject) => {
      setConfirmationConfig({
        message,
        confirmButtonLabel,
        closeButtonLabel,
        icon,
        hasFooterDivider,
        buttonLayout,
        closeOnOverlayClick,
        isOpen: true,
        isTitleCenteredVertical,
        fullWidthButton,
        onConfirm: resolve,
        onClose: reject,
        onSecondaryButtonClick,
      });
    });

    return promise.then(
      () => {
        setConfirmationConfig({ ...confirmationConfig, isOpen: false });

        return true;
      },
      () => {
        setConfirmationConfig({ ...confirmationConfig, isOpen: false });

        return false;
      },
    );
  };

  return {
    ...confirmationConfig,
    confirm,
  };
};
