import React from 'react';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Routes from './routes/Routes';
import { Security } from './auth/Security';
import { ConfirmationModal, Disclaimer, ErrorBoundary } from './components';
import { AppContextProvider } from './providers';
import { MobileNavScreen } from './components/mobile';
import { MobileFilters } from './modules/activities/components';
import { MobileImpactProgressView } from './modules/impact/components/ImpactProgressBars/MobileImpactProgressView';
import { MobileChartBarImpact } from './modules/impact/components/Dashboard/components/MobileChartBarImpact';
import AuthContextProvider from './providers/AuthContextProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 5000,
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#702082',
    },
    secondary: {
      main: '#e8f7f5',
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Security>
            <AuthContextProvider>
              <AppContextProvider>
                <Routes />
                <ConfirmationModal />
                <MobileNavScreen />
                <MobileFilters />
                <MobileImpactProgressView />
                <MobileChartBarImpact />
                <Disclaimer />
              </AppContextProvider>
            </AuthContextProvider>
          </Security>
          {/* {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} position="top-left" />} */}
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
