import React, { createContext, useCallback, useEffect, useState } from 'react';
import { adminService } from 'src/services/admin-service';
import { msalInstance } from 'src/auth/Security';

export interface IDisclaimerContextValue {
  isOpen: boolean;
  onConfirm?: (value?: unknown) => void;
  onClose?: () => void;
}

export interface IDisclaimerContext {
  config: IDisclaimerContextValue;
  setConfig: (value: IDisclaimerContextValue) => void;
}

export const DisclaimerContext = createContext<IDisclaimerContext>({
  config: { isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onConfirm: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setConfig: () => {},
});

const DisclaimerProvider = ({ children }) => {
  const [disclaimerConfig, setDisclaimerConfig] = useState<IDisclaimerContextValue>({
    isOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClose: () => {},
  })

  const confirmDisclaimer = useCallback(() => {
    if (disclaimerConfig.isOpen) {
      return Promise.reject('Another instance of disclaimer modal already opened');
    }

    const promise = new Promise((resolve, reject) => {
      setDisclaimerConfig({
        isOpen: true,
        onConfirm: resolve,
        onClose: reject,
      });
    });

    return promise.then(
      (val) => {
        setDisclaimerConfig({ ...disclaimerConfig, isOpen: false });

        return {OptInName: val};
      },
      () => {
        setDisclaimerConfig({ ...disclaimerConfig, isOpen: false });

        return null;
      },
    );
    }, [disclaimerConfig]);

  const disclaimerCheckFlow = useCallback(async () => {
    const userIsCheckedDisclaimer = await adminService.userExists();
    if (!userIsCheckedDisclaimer) {
      const isConfirmed = await confirmDisclaimer();
      if (isConfirmed) {
        await adminService.createUser(isConfirmed.OptInName);
      }
    }
  }, [confirmDisclaimer]);

  useEffect(() => {
    const intervalCode = setInterval(() => {
      const account = msalInstance.getActiveAccount();
      if (account) {
        clearInterval(intervalCode);
        disclaimerCheckFlow();
      }
    }, 1000);
  }, []);

  return (
    <DisclaimerContext.Provider
      value={{
        config: disclaimerConfig,
        setConfig: setDisclaimerConfig
      }}
    >{children}</DisclaimerContext.Provider>
  );
};
export default DisclaimerProvider;
