import React, { useMemo } from 'react';
import classNames from 'classnames';

import { ActivityType } from 'src/modules/activities/domain';
import { EmissionsIcon, WasteIcon, ElectricityIcon, WaterIcon, InclusionIcon, CommunityIcon } from 'src/assets/icons';

import './ImpactWidget.scss';

export type ImpactWidgetProps = {
  type: ActivityType;
  reductionNumber: number;
  activitiesCount: number;
};

const className = 'impact-widget';

export const ImpactWidget: React.FC<ImpactWidgetProps> = ({ type, reductionNumber, activitiesCount }) => {
  const widgetConfig = {
    [ActivityType.Water]: {
      bottomBorderStyle: 'waterType',
      Icon: WaterIcon,
      unit: 'litres/year',
      displayName: 'Water',
    },
    [ActivityType.Energy]: {
      bottomBorderStyle: 'energyType',
      Icon: ElectricityIcon,
      unit: 'kWh/year',
      displayName: 'Electricity',
    },
    [ActivityType.Waste]: {
      bottomBorderStyle: 'wasteType',
      Icon: WasteIcon,
      unit: 'Level',
      displayName: 'Waste',
    },
    [ActivityType.Emissions]: {
      bottomBorderStyle: 'emissionType',
      Icon: EmissionsIcon,
      unit: 'kg CO2e/year',
      displayName: 'Emissions',
    },
    // [ActivityType.Inclusion]: {
    //   bottomBorderStyle: 'inclusionType',
    //   Icon: InclusionIcon,
    //   unit: null,
    //   displayName: 'Inclusion',
    // },
    // [ActivityType.Community]: {
    //   bottomBorderStyle: 'communityType',
    //   Icon: CommunityIcon,
    //   unit: null,
    //   displayName: 'Community',
    // },
  }[type];

  const { bottomBorderStyle, Icon, unit, displayName } = widgetConfig;

  const metrics = useMemo(() => {
    const finalReductionNumber = Math.round(reductionNumber).toLocaleString();
    let result = `${finalReductionNumber} ${unit}`;

    if (type === ActivityType.Waste) {
      result = `${unit} ${finalReductionNumber}`;
    }

    // if (type === ActivityType.Community || type === ActivityType.Inclusion) {
    //   result = '';
    // }

    return result;
  }, [reductionNumber, type, unit]);

  const activitiesBlock = useMemo(() => {
    // if (type === ActivityType.Community || type === ActivityType.Inclusion) {
    //   return null;
    // }

    return (
      <div className={`${className}__header__activities`}>
        from <b>{activitiesCount ?? 0}</b> {activitiesCount === 1 ? 'Activity' : 'Activities'}
      </div>
    );
  }, [activitiesCount, type]);

  return (
    <div className={classNames(className, `${className}__${bottomBorderStyle} here`)}>
      <div className={`${className}__header`}>
        <Icon />
        <label>{displayName}</label>
      </div>
      <div className={`${className}__header__metrics`}>{metrics}</div>
      {activitiesBlock}
    </div>
  );
};
