import { locationsService } from '../services/locations';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ActivityReqParams } from '../domain';
import { AuthContext } from 'src/providers';

const fetchLocations = async () => {
  const data = await locationsService.getLocations();
  return data;
};

const postLocation = async (location) => {
  const data = await locationsService.postLocation(location);
  return data;
};

export const useLocations = () => {
  const [isLocationsLoading, setIsLoading] = useState(false);
  const [locationsList, setLocationsList] = useState<any>([]);

  const fetch = useCallback(async () => {
    setIsLoading(true);
    const locations = await fetchLocations();
    setLocationsList(locations.data);
    setIsLoading(false);
  }, []);

  async function addLocation(location) {
    await postLocation(location);
    await fetch();
  }

  async function deleteLocation(location) {
    const response = await locationsService.deleteLocation(location);
    if (!response.succeeded) {
      return response;
    }
    await fetch();
  }

  async function reset() {
    setLocationsList(null);
    await fetch();
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLocationsLoading,
    locationsList,
    addLocation,
    deleteLocation,
    reset,
  };
};
