import { InteractionStatus, IPublicClientApplication, type AccountInfo } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { loginRequest } from 'src/auth/authConfig';
import { msalInstance } from 'src/auth/Security';
import { adminService } from 'src/services/admin-service';

export interface Auth {
  isAuthenticated: boolean;
  authState?: AccountInfo;
  instance: IPublicClientApplication;
  inProgress: boolean;
  fullname: string; // User's fullname
  isAdmin: boolean;
  logout: () => void;
}

export const useAuth: () => Auth = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const authState: AccountInfo | undefined = useMemo(() => accounts[0], [accounts]);

  const [firstName, setFirstName] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const handleLogout = useCallback(() => {
      instance.logoutRedirect()
  }, [instance]);

  const acquireUserFirstName = useCallback(async () => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        return;
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const { accessToken } = response;

    const decodedToken = atob(accessToken.split('.')[1]);

    const parsedToken = JSON.parse(decodedToken);

    setFirstName(parsedToken["given_name"]);

  // isAuthenticated dep is mandatory here, otherwise the hook will not be called when the user logs in
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    acquireUserFirstName();
  }, [acquireUserFirstName]);

  const checkIsAdmin = useCallback(async () => {
    if (authState?.username) {
      const result = await adminService.isAdmin(authState.username);
      setIsAdmin(result);
    }
  }, [authState?.username]);

  useEffect(() => {
    checkIsAdmin();
  }, [checkIsAdmin])

  return {
    // shortcut helper
    isAuthenticated,

    // is authorization in progress
    inProgress: inProgress !== InteractionStatus.None,

    // pass-through objects
    authState,

    instance,

    fullname: firstName,
    isAdmin,

    logout: handleLogout,
  };
};
