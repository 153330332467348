import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { activityService } from 'src/modules/activities/services';
import { GetReportReqParams, IReportItem } from '../domain';

const defaultFilters = {
  search: ''
}

export const useReport = () => {
  const [data, setData] = useState<IReportItem[]>([]);
  // const [data] = useState<IReportItem[]>(activityService.getReportMocked());
  const loading = !data.length;

  const [filters, setFilters] = useState<GetReportReqParams>(defaultFilters);

  const updateFilters = (filters: Partial<GetReportReqParams>) => setFilters((prev) => ({ ...prev, ...filters }));

  const location = useLocation();

  useEffect(() => {
    updateFilters(defaultFilters);
  }, [location.pathname]);

  const fetch = useCallback(async () => {
    const report = await activityService.getReport();
    setData(report);
  }, []);

  useEffect(() => {
    fetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    report: data,
    loading: loading,
    filters,
    updateFilters,
  };
};
