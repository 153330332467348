export const newActivityNotes = [
  '‘Title’ and ‘Descriptions’ should be kept short.',
  '‘Notes’ are for admin use only and do not appear on the website itself.',
  '‘Sources’ are to add in website addresses etc. related to the activity. These also do not appear on the site.',
  '‘Location’ – please select from dropdown, or add a new location first on the ‘Location’ tab.',
  '‘Difficulty’ and ‘Impact’ are subjective assessments, so use existing activities as a guide.',
  '‘Water’, ‘Energy’ and ‘Emissions’ reductions should be given on an ANNUAL basis.',
  'Water is calculated in LITRES. Energy is calculated in kWh. Emissions are calculated in KG of CO2e.',
  // eslint-disable-next-line max-len
  'When you add a ‘Water’ and ‘Energy” amount a calculation will be offered for the amount of kg CO2 saved. Where an activity has both a Water and an Energy component, then don’t forget to add both of the auto-calculated numbers and then add to the ‘Emissions’ section.',
  'If you already have emissions data from another source, then feel free to use this instead.',
  '‘Inclusion’ and ‘Community’ activities do not require any metrics to be added.',
];
