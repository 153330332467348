import classNames from 'classnames';
import './ImpactLabel.scss';
import { Tooltip } from '@mui/material';
import { ImpactsName } from '../../domain/helpers/impacts';

const className = 'impact-label';

export const ImpactLabel = ({ impactsList }) => {
  return (
    <div className={`${className}-wrap`}>
      {Object.entries(impactsList)
        .filter(([key, value]) => value)
        .map(([key], index) => (
          <Tooltip key={index} title={ImpactsName[key]}>
            <div className={classNames(className, `${className}__${key}`)} />
          </Tooltip>
        ))}
    </div>
  );
};
