import React from 'react';
import classNames from 'classnames';

import { ActivityType } from 'src/modules/activities/domain';
import { EmissionsIcon, WasteIcon, ElectricityIcon, WaterIcon, InclusionIcon, CommunityIcon } from 'src/assets/icons';

import './ViewActivitiesWidget.scss';
import { Button } from 'src/components';

export type ViewActivitiesWidgetProps = {
  type: ActivityType;
  onClick: () => void;
};

const className = 'view-activities-widget';

export const ViewActivitiesWidget: React.FC<ViewActivitiesWidgetProps> = ({ type, onClick }) => {
  const cardConfig = {
    [ActivityType.Water]: {
      style: 'waterType',
      Icon: WaterIcon,
      buttonText: 'View Water Activities',
    },
    [ActivityType.Energy]: {
      style: 'energyType',
      Icon: ElectricityIcon,
      buttonText: 'View Electricity Activities',
    },
    [ActivityType.Waste]: {
      style: 'wasteType',
      Icon: WasteIcon,
      buttonText: 'View Waste Activities',
    },
    [ActivityType.Emissions]: {
      style: 'emissionType',
      Icon: EmissionsIcon,
      buttonText: 'View Emissions Activities',
    },
    // [ActivityType.Inclusion]: {
    //   style: 'inclusionType',
    //   Icon: InclusionIcon,
    //   buttonText: 'View Inclusion Activities',
    // },
    // [ActivityType.Community]: {
    //   style: 'communityType',
    //   Icon: CommunityIcon,
    //   buttonText: 'View Community Activities',
    // },
  }[type];

  const { style, Icon, buttonText } = cardConfig;

  return (
    <div className={className}>
      <div className={classNames(`${className}__image`, `${className}__image__${style}`)} />
      <div className={`${className}__line`} />
      <div className={classNames(`${className}__background`, `${className}__background__${style}`)} />
      <div className={`${className}__overflow`}>
        <div className={`${className}__icon-container`}>
          <Icon />
        </div>
        <Button
          className={classNames(`${className}__button`, `${className}__button__${style}`)}
          fullWidth={false}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
