import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { IBaseComponent } from 'src/types';

import './Table.scss';

const Table: React.ForwardRefRenderFunction<HTMLTableElement, IBaseComponent> = ({ children, className }, ref) => (
  <table ref={ref} className={classNames('table', className)}>
    {children}
  </table>
);

export default forwardRef(Table);
