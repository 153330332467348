import { useCallback, useContext, useMemo, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import classNames from 'classnames';

import { ActivitiesTab } from './consts';
import { difficultyOptions, impactOptions, locationOptions, typeOptions } from './helpers';
import { Button, SingleSelect } from 'src/components';
import { useViewport } from 'src/hooks';
import { ActivitiesTable, ImpactWidget, ImpactWidgetProps, ViewActivitiesWidget } from './components';
import { ActivityType } from './domain';
import { useActivities, useUserActivitiesImpact } from './hooks';
import carouselImages from 'src/assets/images/carousel-banners';
import { Filters } from 'src/assets/icons';
import { AuthContext } from 'src/providers';

import './ActivitiesPage.scss';
import { useLocations } from '../administration/hooks';

const className = 'activities-page';

export const ActivitiesPage = () => {
  const { fullname } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState<ActivitiesTab>(ActivitiesTab.All);
  const {
    activities,
    loading,
    loaded,
    loadActivities,
    filters,
    updateFilters,
    setFilters,
    reset,
    toggleFilters: toggleMobileFilters,
    removeActivity,
  } = useActivities(activeTab);
  const { locationsList } = useLocations();
  const { userActivitiesImpact, reset: resetUserActivitiesImpact } = useUserActivitiesImpact();

  const { isDesktop } = useViewport();

  const onNavigateButtonClick = useCallback(() => {
    setActiveTab(activeTab === ActivitiesTab.All ? ActivitiesTab.My : ActivitiesTab.All);
    reset();
  }, [activeTab, reset]);

  const onActivityWidgetClick = useCallback(
    (activityType: ActivityType) => {
      setFilters({ type: activityType });
      reset();
    },
    [reset, setFilters],
  );

  const onActivityAction = useCallback(
    (activityId: number, shouldRemoveActivity: boolean) => {
      shouldRemoveActivity && removeActivity(activityId);
      resetUserActivitiesImpact();
    },
    [removeActivity, resetUserActivitiesImpact],
  );

  const onFiltersIconClick = useCallback(() => {
    toggleMobileFilters();
  }, [toggleMobileFilters]);

  const onResetFiltersClick = useCallback(() => {
    setFilters({});
    reset();
  }, [reset, setFilters]);

  const activitiesPageConfig = {
    [ActivitiesTab.All]: {
      navigateButtonText: 'View my activities',
      headerText: 'Activities',
    },
    [ActivitiesTab.My]: {
      navigateButtonText: 'View activities',
      headerText: 'My Activities',
    },
  }[activeTab];

  const { navigateButtonText, headerText } = activitiesPageConfig;

  const impactWidgets: ImpactWidgetProps[] = useMemo(
    () => [
      {
        type: ActivityType.Water,
        reductionNumber: userActivitiesImpact?.waterImpact,
        activitiesCount: userActivitiesImpact?.waterActivitiesNumber,
      },
      {
        type: ActivityType.Energy,
        reductionNumber: userActivitiesImpact?.energyImpact,
        activitiesCount: userActivitiesImpact?.energyActivitiesNumber,
      },
      {
        type: ActivityType.Waste,
        reductionNumber: userActivitiesImpact?.wasteImpact,
        activitiesCount: userActivitiesImpact?.wasteActivitiesNumber,
      },
      {
        type: ActivityType.Emissions,
        reductionNumber: userActivitiesImpact?.emissionsImpact,
        activitiesCount: userActivitiesImpact?.emissionsActivitiesNumber,
      },
      // {
      //   type: ActivityType.Inclusion,
      //   reductionNumber: userActivitiesImpact?.inclusionImpact,
      //   activitiesCount: userActivitiesImpact?.inclusionActivitiesNumber,
      // },
      // {
      //   type: ActivityType.Community,
      //   reductionNumber: userActivitiesImpact?.communityImpact,
      //   activitiesCount: userActivitiesImpact?.communityActivitiesNumber,
      // },
    ],
    [userActivitiesImpact],
  );

  const TableHeaderDesktop = () => (
    <div className={`${className}__table-header`}>
      <h1>{headerText}</h1>
      <SingleSelect
        className={`${className}__select`}
        name="type"
        placeholder="Choose type"
        options={typeOptions}
        value={filters.type}
        clearable
        onChange={(type) => updateFilters({ type })}
      />
      <SingleSelect
        className={`${className}__select`}
        name="difficulty"
        placeholder="Choose difficulty"
        options={difficultyOptions}
        value={filters.difficulty}
        clearable
        onChange={(difficulty) => updateFilters({ difficulty })}
      />
      <SingleSelect
        className={`${className}__select`}
        name="impact"
        placeholder="Choose impact"
        options={impactOptions}
        value={filters.impact}
        clearable
        onChange={(impact) => updateFilters({ impact })}
      />
      <SingleSelect
        className={`${className}__select`}
        name="location"
        placeholder="Choose location"
        options={locationOptions(locationsList)}
        value={filters.location}
        clearable
        onChange={(location) => updateFilters({ location })}
      />
      <Button onClick={onResetFiltersClick} fullWidth={false} className={`${className}__reset-filters`}>
        Reset all filters
      </Button>
      <Button className="view-my-activities-button" onClick={onNavigateButtonClick} fullWidth={false}>
        {navigateButtonText}
      </Button>
    </div>
  );

  const mobileTableTabs = [
    { activityTabKey: ActivitiesTab.All, text: 'Activities' },
    { activityTabKey: ActivitiesTab.My, text: 'My Activities' },
  ];

  const TableHeaderMobile = () => (
    <div className={`${className}__table-header-mobile`}>
      <div className={`${className}__table-header-mobile__navs`}>
        {mobileTableTabs.map(({ activityTabKey, text }, index) => (
          <div
            key={index}
            className={classNames(`${className}__table-header-mobile__navs__nav`, {
              [`${className}__table-header-mobile__navs__nav--active`]: activeTab === activityTabKey,
            })}
            onClick={onNavigateButtonClick}
          >
            {text}
          </div>
        ))}
      </div>
      <Button
        onClick={onFiltersIconClick}
        fullWidth={false}
        className={`${className}__table-header-mobile__filters-icon`}
      >
        <Filters />
      </Button>
    </div>
  );

  return (
    <div className={className}>
      <div className={`${className}__header`}>
        <h1>Hi, {fullname} your impact is</h1>
        <div className={`${className}__widgets`}>
          {impactWidgets.map((widget, i) => (
            <ImpactWidget key={i} {...widget} />
          ))}
        </div>
      </div>
      <Carousel infiniteLoop showStatus={false} showArrows={false} showThumbs={false} autoPlay interval={4000}>
        {carouselImages.map((src, idx) => (
          <img
            key={idx}
            className={`${className}__image`}
            src={src}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          />
        ))}
      </Carousel>
      <div className={`${className}__view-activities-widgets`}>
        {Object.values(ActivityType).map((activityType, idx) => (
          <ViewActivitiesWidget key={idx} type={activityType} onClick={() => onActivityWidgetClick(activityType)} />
        ))}
      </div>
      <div>
        {isDesktop ? <TableHeaderDesktop /> : <TableHeaderMobile />}
        <ActivitiesTable
          data={activities}
          loading={loading}
          loaded={loaded}
          onLoaderReached={loadActivities}
          tableTab={activeTab}
          onActivityAction={onActivityAction}
        />
      </div>
    </div>
  );
};
