import { IReportItem } from 'src/modules/report/domain';
import { BaseService } from 'src/services/base-service';
import { RequestQueryParams } from 'src/types';
import { ActivitiesResponse, ActivityList, ActivityReqParams, UserActivitiesImpact } from '../domain';

interface ILocationResponse {
  errors: any;
  message: string;
  succeeded: boolean;
}

class LocationsService extends BaseService {
  constructor() {
    super({ basePath: 'Locations' });
  }

  async list(options: Partial<ActivityReqParams> & Omit<RequestQueryParams, 'sortOrder'>) {
    const params = this.getQueryParams(options);
    const response = await this.api.get<{ data: ActivityList }>(`/get-filtered`, { params });

    return response;
  }

  async getLocations() {
    const response = await this.api.get<ActivitiesResponse>(`/get-filtered`);
    return response.data;
  }

  async postLocation(Name) {
    const response = await this.api.post(`/insert`, { Name });
    return response.data;
  }

  async deleteLocation(locationId): Promise<ILocationResponse> {
    const response = await this.api.delete(`/delete`, { data: locationId });
    return response.data;
  }
}

export const locationsService = new LocationsService();
