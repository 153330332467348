import React, { createContext, useState } from 'react';
import { ChartBarTooltipDataList } from 'src/modules/impact/domain';

export const MobileChartBarImpactProviderContext = createContext<[MobileChartBarImpactProviderContextValue,
  (value: Partial<MobileChartBarImpactProviderContextValue>) => void,
  (value: MobileChartBarImpactProviderContextValue) => void
]>([
  {} as MobileChartBarImpactProviderContextValue,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

export interface MobileChartBarImpactProviderContextValue {
  isOpen: boolean;
  data: ChartBarTooltipDataList;
}

const MobileChartBarImpactProvider = ({ children }) => {
  const [config, setConfig] = useState<MobileChartBarImpactProviderContextValue>({
    isOpen: false,
    data: [],
  });

  const updateConfig = (update: Partial<MobileChartBarImpactProviderContextValue>) => setConfig((prev) => ({ ...prev, ...update }));

  return (
    <MobileChartBarImpactProviderContext.Provider value={[config, updateConfig, setConfig]}>
      {children}
    </MobileChartBarImpactProviderContext.Provider>
  );
};
export default MobileChartBarImpactProvider;
