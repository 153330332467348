import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function AlertDialog({ open, handleClose, onAgree, onDisagree }) {
  //   const [open, setOpen] = useState(false);

  const handleAgree = () => {
    handleClose();
    onAgree?.();
  };

  const handleDisadgree = () => {
    handleClose();
    onDisagree?.();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete selected items? It has an impact to all users.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisadgree} color="error" variant="contained">
            No
          </Button>
          <Button onClick={handleAgree} color="success" autoFocus variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
