import React, { createContext, ReactNode, useState } from 'react';

export const ConfirmContext = createContext<[ConfirmContextValue, (value: ConfirmContextValue) => void]>([
  {} as ConfirmContextValue,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

export type ButtonLayout = 'vertical' | 'horizontal' | 'horizontal-space-between';

export interface ConfirmContextValue {
  message: string | React.ReactElement;
  confirmButtonLabel: string;
  closeButtonLabel: string;
  icon: ReactNode | null;
  hasFooterDivider: boolean;
  buttonLayout: ButtonLayout;
  closeOnOverlayClick: boolean;
  isOpen: boolean;
  isTitleCenteredVertical: boolean;
  fullWidthButton: boolean;
  onConfirm?: (value?: unknown) => void;
  onClose?: () => void;
  onSecondaryButtonClick?: () => void;
}

const ConfirmContextProvider = ({ children }) => {
  const [confirmationConfig, setConfirmationConfig] = useState<ConfirmContextValue>({
    message: '',
    confirmButtonLabel: '',
    closeButtonLabel: '',
    icon: null,
    hasFooterDivider: true,
    buttonLayout: 'horizontal',
    closeOnOverlayClick: false,
    isOpen: false,
    isTitleCenteredVertical: false,
    fullWidthButton: false,
    onConfirm: null,
    onClose: null,
    onSecondaryButtonClick: null,
  });

  return (
    <ConfirmContext.Provider value={[confirmationConfig, setConfirmationConfig]}>{children}</ConfirmContext.Provider>
  );
};
export default ConfirmContextProvider;
