import { BaseService } from "src/services/base-service";

class AdminService extends BaseService {
    constructor() {
        super({ basePath: 'Administration' });
    }

    async isAdmin(email: string): Promise<boolean> {
        const result = await this.api.get(`/user/IsAdmin`, {
            params: {
                email,
            }
        });

        return result.data;
    }

    async createUser(OptInName): Promise<void> {
        console.log(OptInName);
        await this.api.post(`/user/Create`, null, {
                params: {
                    OptInName,
            }
        });
    }

    async userExists(): Promise<boolean> {
        const result = await this.api.get(`/user/Exists`);

        return result.data;
    }
}

export const adminService = new AdminService();
