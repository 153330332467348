import React from 'react';

import { Empty } from '../Empty';
import TableLoader from '../TableLoader';
import classNames from 'classnames';

interface ILoadableTableBody {
  children: React.ReactNode;
  noResult: boolean;
  loading: boolean;
  className?: string;
  customEmptyState?: React.ReactNode;
}

const LoadableTableBody: React.FC<ILoadableTableBody> = ({ children, loading, noResult, className, customEmptyState }) => {
  let bodyContent = children;

  if (noResult && !loading) {
    bodyContent = customEmptyState ?? <Empty center />;
  }

  return (
    <tbody className={classNames(className, "table-body")}>
      {bodyContent}
      {loading && <TableLoader />}
    </tbody>
  );
};

export default LoadableTableBody;
