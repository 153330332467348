import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { Button } from 'src/components';

import { activityDifficultyInNumber, activityImpactInNumber } from '../../consts';
import { ActivityType, IActivity, IImpactCard } from '../../domain';

import './ActivityCard.scss';
import { EllipsesInRow, EllipsisColor } from './components';
import { ImpactCard } from './components/Impact';
import { activityService } from '../../services';
import { useConfirm, useViewport } from 'src/hooks';
import { ArrowRightIcon } from 'src/assets/icons';

interface ActivityCardProps {
  activity: Partial<IActivity>;
  isMy: boolean;
  isExpanded: boolean;
  onActivityAction: (actionId: number, shouldRemoveActivity: boolean) => void;
  onActivityExpand: (actionId: number | null) => void;
}

const className = 'activity-card';

export const ActivityCard: React.FC<ActivityCardProps> = ({
  activity,
  isMy,
  isExpanded,
  onActivityAction,
  onActivityExpand,
}) => {
  const {
    activityId,
    locations,
    isWater,
    isEnergy,
    isWaste,
    isEmissions,
    isInclusion,
    isCommunity,
    difficulty,
    impact,
    positiveAction,
    description,
    energyReduction,
    waterReduction,
    wasteReduction,
    tcO2eReduction,
    inclusionReduction,
    communityReduction,
  } = activity;

  const impacts: IImpactCard[] = useMemo(() => {
    const list = [];
    if (isWater) {
      list.push({
        type: ActivityType.Water,
        reductionNumber: waterReduction,
      });
    }

    if (isEnergy) {
      list.push({
        type: ActivityType.Energy,
        reductionNumber: energyReduction,
      });
    }

    if (isWaste) {
      list.push({
        type: ActivityType.Waste,
        reductionNumber: wasteReduction,
      });
    }

    if (isEmissions) {
      list.push({
        type: ActivityType.Emissions,
        reductionNumber: tcO2eReduction,
      });
    }

    // if (isInclusion) {
    //   list.push({
    //     type: ActivityType.Inclusion,
    //     reductionNumber: inclusionReduction,
    //   });
    // }

    // if (isCommunity) {
    //   list.push({
    //     type: ActivityType.Community,
    //     reductionNumber: communityReduction,
    //   });
    // }

    return list;
  }, [energyReduction, isEmissions, isEnergy, isWaste, isWater, tcO2eReduction, wasteReduction, waterReduction]);

  const difficultyEllipsisesFilledQuantity: number = activityDifficultyInNumber[difficulty];

  const impactEllipsesFilledQuantity: number = activityImpactInNumber[impact];

  const { confirm } = useConfirm();
  const { isDesktop } = useViewport();

  const onButtonClick = useCallback(async () => {
    let shouldRemoveActivity = true;
    if (isMy) {
      const isConfirmed = await confirm(`Are you sure you want to remove this activity?`, {
        confirmButtonLabel: 'Yes',
        closeButtonLabel: 'No',
        hasFooterDivider: false,
        buttonLayout: 'horizontal',
        fullWidthButton: true,
      });
      if (!isConfirmed) {
        shouldRemoveActivity = false;
      }
      isConfirmed && (await activityService.unsubscribe({ activityId }));
    } else {
      await activityService.subscribe({ activityId });
    }

    onActivityAction(activityId, shouldRemoveActivity);
  }, [activityId, confirm, isMy, onActivityAction]);

  return (
    <div className={classNames(className, { [`${className}--expanded`]: isExpanded })}>
      <div className={`${className}-header`}>
        <div className={`${className}-header-item`}>
          Impact:{' '}
          <div className={`${className}-header-item__value`}>
            <EllipsesInRow color={EllipsisColor.Green} filledQuantity={impactEllipsesFilledQuantity} />
          </div>
        </div>
        <div className={`${className}-header-item`}>
          Difficulty:{' '}
          <div className={`${className}-header-item__value`}>
            <EllipsesInRow color={EllipsisColor.Blue} filledQuantity={difficultyEllipsisesFilledQuantity} />
          </div>
        </div>
      </div>
      <div className={`${className}-positiveAction`}>{positiveAction}</div>
      <div
        className={classNames(`${className}-description`, {
          [`${className}-description--show-more`]: isExpanded,
        })}
        onClick={() => onActivityExpand(activityId)}
      >
        {description}
      </div>
      {!isExpanded && (
        <div className={`${className}-see-more`} onClick={() => onActivityExpand(activityId)}>
          {isDesktop ? 'Click here to see more' : 'Tap to see more'}
        </div>
      )}
      {isDesktop && isExpanded && (
        <div className={`${className}-see-more`} onClick={() => onActivityExpand(null)}>
          Click here to see less
        </div>
      )}
      <div className={`${className}-footer`}>
        <div className={`${className}-impacts`}>
          {impacts.map((impact, i) => (
            <ImpactCard key={i} {...impact} />
          ))}
        </div>
        <div className={`${className}-bottom`}>
          <div className={`${className}-location`}>
            {locations.map((location) => location.name).join(',')}
            {/* <ArrowRightIcon /> */}
          </div>
          <Button
            className={classNames(`${className}-commit-button`, {
              [`${className}-commit-button--red`]: isMy,
              [`${className}-commit-button--purple`]: !isMy,
            })}
            onClick={onButtonClick}
            fullWidth={false}
          >
            {isMy ? (
              'Remove'
            ) : (
              <>
                Commit <ArrowRightIcon />
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
