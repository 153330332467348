import React from 'react';
import classNames from 'classnames';

import './Empty.scss';

interface EmptyProps {
  center?: boolean;
  className?: string;
}

export const Empty: React.FC<EmptyProps> = ({ center, className }) => {
  return (
    <tr
      className={classNames('table-empty', className, {
        'table-empty--center': center,
      })}
    >
      <td colSpan={100}>No items found</td>
    </tr>
  );
};
