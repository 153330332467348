import { type AxiosInstance } from 'axios';

import createAPIClient from './api';

export class BaseService {
  protected api: AxiosInstance;

  constructor({ basePath }) {
    this.api = createAPIClient({ basePath });
  }

  // protected getQueryParams(options) {
  //   const params = new URLSearchParams();
  //   Object.keys(options).forEach((key) => {
  //     if (Array.isArray(options[key])) {
  //       options[key].forEach((v) => params.append(key, v?.toString()));
  //     } else if (options[key] !== '' && options[key] != null) {
  //       params.append(key, options[key]?.toString());
  //     }
  //   });

  //   console.log(params);

  //   return params;
  // }

  protected getQueryParams(options) {
    if (!options) return;

    const params = new URLSearchParams();

    if (options.textSearch) {
      params.append('TextSearch', options.textSearch);
    }
    const searchFields: string[] = [];
    if (options.type) {
      searchFields.push(`is${options.type}=true`);
    }
    if (options.difficulty) {
      searchFields.push(`Difficulty=${options.difficulty}`);
    }
    if (options.impact) {
      searchFields.push(`Impact=${options.impact}`);
    }
    if (options.location) {
      searchFields.push(`Location=${options.location}`);
    }
    if (options.isHidden != null) {
      searchFields.push(`IsHidden=${options.isHidden}`);
    }
    params.append('SearchFields', searchFields.map((field) => decodeURIComponent(field)).join('&'));

    if (options.isUserActivities) {
      params.append('IsUserActivities', options.isUserActivities);
    }
    if (options.pageNumber != null) {
      params.append('PageNumber', options.pageNumber.toString());
    }
    if (options.pageSize != null) {
      params.append('PageSize', options.pageSize.toString());
    }

    return params;
  }
}
