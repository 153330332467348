import React, { useMemo } from 'react';

import './ImpactPage.scss';
import { Dashboard, ImpactPageSection, ImpactProgressBars } from './components';
import { useUserActivitiesImpact } from '../activities/hooks';
import { impactLevelsPerType, yourImpactDescription } from './consts';
import { ActivityType } from '../activities/domain';
import { useCompanyLocationsWithEmployeeCount, useUserActivitiesImpactInUnderstandableUnits } from './hooks';
import { CompanyHierarchyLocationsWithEmployeeCountEnum, DashboardData, DesktopChartBarTooltipData } from './domain';
import { capitalize } from 'src/helpers';
import { createSentenceOfUnderstandableUnits } from './helpers';

const className = 'impact-page';

export const ImpactPage: React.FC = () => {
  const { userActivitiesImpact } = useUserActivitiesImpact();
  const { companyLocationsWithEmployeeCount } = useCompanyLocationsWithEmployeeCount();
  const { userActivitiesImpactInUnderstandableUnits } = useUserActivitiesImpactInUnderstandableUnits();

  const userActivitiesTotalCount = useMemo(
    () => userActivitiesImpact?.totalHighLevelActivitiesNumber,
    [userActivitiesImpact?.totalHighLevelActivitiesNumber],
  );

  const impacts = useMemo(
    () =>
      Object.entries(impactLevelsPerType).map(([key, value]) => {
        const type = key as ActivityType;

        const userImpactValue = {
          [ActivityType.Water]: userActivitiesImpact?.waterImpact,
          [ActivityType.Energy]: userActivitiesImpact?.energyImpact,
          [ActivityType.Waste]: userActivitiesImpact?.wasteImpact,
          [ActivityType.Emissions]: userActivitiesImpact?.emissionsImpact,
          // [ActivityType.Inclusion]: userActivitiesImpact?.inclusionImpact,
          // [ActivityType.Community]: userActivitiesImpact?.communityImpact,
        }[type];

        const sentencesWithUnderstanbleUnits = userActivitiesImpactInUnderstandableUnits
          ?.filter((el) => capitalize(el.typeName) === type)
          .map((item) => createSentenceOfUnderstandableUnits(item));

        return {
          type,
          userImpactValue,
          maxValue: value.maxValue,
          medalsMinValues: value.medalsMinValues,
          tooltipData: sentencesWithUnderstanbleUnits ?? [],
        };
      }),
    [
      userActivitiesImpact?.emissionsImpact,
      userActivitiesImpact?.energyImpact,
      userActivitiesImpact?.wasteImpact,
      userActivitiesImpact?.waterImpact,
      userActivitiesImpact?.communityImpact,
      userActivitiesImpact?.inclusionImpact,
      userActivitiesImpactInUnderstandableUnits,
    ],
  );

  const dashboardsByType: DashboardData | null = useMemo(() => {
    return companyLocationsWithEmployeeCount && userActivitiesImpact
      ? Object.keys(ActivityType).reduce((res: DashboardData, type: ActivityType) => {
          const userImpactValue = {
            [ActivityType.Water]: userActivitiesImpact?.waterImpact,
            [ActivityType.Energy]: userActivitiesImpact?.energyImpact,
            [ActivityType.Waste]: userActivitiesImpact?.wasteImpact,
            [ActivityType.Emissions]: userActivitiesImpact?.emissionsImpact,
            // [ActivityType.Inclusion]: userActivitiesImpact?.inclusionImpact,
            // [ActivityType.Community]: userActivitiesImpact?.communityImpact,
          }[type];

          const locationToImpactValueMap = Object.entries(companyLocationsWithEmployeeCount).reduce(
            (result, [location, value]) => {
              if (!result[location]) {
                result[location] = userImpactValue * value;
              }

              return result;
            },
            {},
          );

          locationToImpactValueMap[CompanyHierarchyLocationsWithEmployeeCountEnum.you] = userImpactValue;

          const chartDataByType = [
            {
              order: 1,
              name: 'You',
              value: userImpactValue,
            },
          ];

          Object.entries(companyLocationsWithEmployeeCount).forEach(([key, value], index) => {
            chartDataByType.push({
              order: index + 2,
              name: capitalize(key),
              value: value * userImpactValue,
            });
          });

          const desktopTooltipData = Object.values(CompanyHierarchyLocationsWithEmployeeCountEnum).reduce(
            (result, location) => {
              const sentencesWithUnderstanbleUnits = userActivitiesImpactInUnderstandableUnits
                ?.filter((el) => capitalize(el.typeName) === type)
                .map(({ value, treesEquivalentValue, ...item }) => {
                  let newValue = 0;
                  let newTreesEquivalentValue = 0;

                  switch (location) {
                    case CompanyHierarchyLocationsWithEmployeeCountEnum.you: {
                      newValue = value;
                      newTreesEquivalentValue = treesEquivalentValue;
                      break;
                    }
                    case CompanyHierarchyLocationsWithEmployeeCountEnum.office: {
                      newValue = value * companyLocationsWithEmployeeCount.office;
                      newTreesEquivalentValue = treesEquivalentValue * companyLocationsWithEmployeeCount.office;
                      break;
                    }
                    case CompanyHierarchyLocationsWithEmployeeCountEnum.country: {
                      newValue = value * companyLocationsWithEmployeeCount.country;
                      newTreesEquivalentValue = treesEquivalentValue * companyLocationsWithEmployeeCount.country;
                      break;
                    }
                    case CompanyHierarchyLocationsWithEmployeeCountEnum.region: {
                      newValue = value * companyLocationsWithEmployeeCount.region;
                      newTreesEquivalentValue = treesEquivalentValue * companyLocationsWithEmployeeCount.region;
                      break;
                    }
                    case CompanyHierarchyLocationsWithEmployeeCountEnum.company: {
                      newValue = value * companyLocationsWithEmployeeCount.company;
                      newTreesEquivalentValue = treesEquivalentValue * companyLocationsWithEmployeeCount.company;
                      break;
                    }
                  }
                  return createSentenceOfUnderstandableUnits({
                    ...item,
                    value: newValue,
                    treesEquivalentValue: newTreesEquivalentValue,
                  });
                });

              return Object.assign(result, {
                [location]: {
                  impactValue: locationToImpactValueMap[location],
                  sentences: sentencesWithUnderstanbleUnits ?? [],
                },
              });
            },
            {} as DesktopChartBarTooltipData,
          );

          const measureUnit = {
            [ActivityType.Water]: 'l/year',
            [ActivityType.Energy]: 'kWh/year',
            [ActivityType.Emissions]: 'kg CO2e/year',
            // [ActivityType.Inclusion]: 'inclusion/year',
            // [ActivityType.Community]: 'community/year',
          }[type];

          const mobileTooltipData = Object.values(CompanyHierarchyLocationsWithEmployeeCountEnum).map((value) => {
            let title = JSON.parse(JSON.stringify(value));
            if (value === CompanyHierarchyLocationsWithEmployeeCountEnum.you) {
              title = 'Your';
            }

            return {
              title: `${capitalize(title)} impact equals: ${locationToImpactValueMap[value]} ${measureUnit}`,
              sentences: desktopTooltipData[value].sentences,
            };
          });

          return Object.assign(res, {
            [type]: {
              chartData: chartDataByType,
              tooltipData: {
                mobile: mobileTooltipData,
                desktop: desktopTooltipData,
              },
            },
          });
        }, {} as DashboardData)
      : null;
  }, [companyLocationsWithEmployeeCount, userActivitiesImpact, userActivitiesImpactInUnderstandableUnits]);

  return (
    <div className={className}>
      <ImpactPageSection title="Your Impact" description={yourImpactDescription}>
        <ImpactProgressBars userActivitiesTotalCount={userActivitiesTotalCount} impacts={impacts} />
      </ImpactPageSection>
      <ImpactPageSection title="What if others in Dentons followed your actions?">
        {dashboardsByType && <Dashboard data={dashboardsByType} />}
      </ImpactPageSection>
    </div>
  );
};
