import React from 'react';
import classNames from 'classnames';

import { uiLabels } from '../labels';

import './index.scss';

const isOptionSelected = (optionValue, value) => {
  return optionValue === value;
};

const MobileSelect = ({
  options,
  value,
  onChange,
  showCheckboxes = true,
  className = '',
  emptyMessage = uiLabels.noOptions,
}: any) => {
  const handleOptionClick = (e, optionValue) => {
    e.stopPropagation();
    onChange(value !== optionValue ? optionValue : undefined);
  };

  return (
    <div
      className={classNames(className, 'mobile-select')}
    >
      <div className="mobile-select__options">
        {options?.length ? (
            options.map(({ label, value: optionValue }) => (
              <div
                key={optionValue}
                className={classNames('mobile-select__option', {
                    'mobile-select__option--checkbox': showCheckboxes,
                    'mobile-select__option--selected': isOptionSelected(optionValue, value),
                  })}
                onClick={(e) => handleOptionClick(e, optionValue)}
                >
                {label}
              </div>
            ))
          ) : (
            <div className="mobile-select__option mobile-select__option--empty">{emptyMessage}</div>
          )}
      </div>
    </div>
  );
};

export default MobileSelect;

export const MobileSingleSelect = (props) => <MobileSelect {...props} />;
