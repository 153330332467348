import React, { useEffect, useState, useCallback, useRef, memo } from 'react';
import { msalInstance } from 'src/auth/Security';
import { useIntersectionObserver } from 'src/hooks';

import './TableInfiniteScroll.scss';

interface ITableInfiniteScroll {
  loading: boolean;
  onLoaderReached(): Promise<unknown>;
  initialRequest?: boolean;
  loaded: boolean;
}

const TableInfiniteScroll: React.FC<ITableInfiniteScroll> = memo(
  ({ loading, onLoaderReached, initialRequest = false, loaded }) => {
    const [footerElement, setFooterElement] = useState<HTMLDivElement>(null);
    const initialized = useRef(false);
    const shouldLoading = !loading && !loaded;

    const handleObserve = useCallback(
      (element: IntersectionObserverEntry) => {
        if (!loading && element?.isIntersecting && !loaded && initialized.current === true) {
          onLoaderReached();
        }
      },
      [loaded, loading, onLoaderReached],
    );

    useIntersectionObserver(footerElement, handleObserve);

    useEffect(() => {
      const intervalCode = setInterval(() => {
        const account = msalInstance.getActiveAccount();
        if (account) {
          clearInterval(intervalCode);
          if (initialized.current === false && shouldLoading && initialRequest) {
            onLoaderReached();
          }
          initialized.current = true;
        }
      }, 1000);
    }, [initialRequest, loaded, loading, onLoaderReached, shouldLoading]);

    if (loading) {
      return null;
    }

    return <div className="table-infinite-loader" ref={setFooterElement} />;
  },
);

export default TableInfiniteScroll;
