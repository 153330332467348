import React, { useState } from 'react';
import { FormControl, InputLabel, InputAdornment, IconButton, TextField, Input, Select } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { IRowsActivities } from '../ActivitiesList';

export function EnhancedSearch({ rows, filterRows }) {
  const originalRows: IRowsActivities[] = JSON.parse(JSON.stringify([...rows]));
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredRows = originalRows.filter((item) =>
      item.positiveAction.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    filterRows(filteredRows);
  };

  return (
    <>
      <FormControl sx={{ px: 2, mb: 2, boxSizing: 'border-box' }} fullWidth>
        <InputLabel htmlFor="search-adornment">Search</InputLabel>
        <Input
          id="outlined-adornment"
          onChange={handleInput}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  );
}
