import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { generatePath } from 'react-router-dom';

import { EmptyTableState, LoadableTableBody, Table, TableCell, TableHead, TableRow, TableSortableHeaderCell } from "src/components"
import ScrollContainer from "src/components/ScrollContainer";
import { ActivityType } from 'src/modules/activities/domain';
import paths from 'src/routes/paths';
import { IReportItem } from '../../domain';
import { ReportImpactCard } from './components';

import './ReportTable.scss';

interface ReportTableProps {
  loading: boolean;
  data: IReportItem[];
  setData: React.Dispatch<React.SetStateAction<IReportItem[]>>;
  history: any;
  location: any;
}

const className = 'report-table';

export const ReportTable: React.FC<ReportTableProps> = ({ loading, data, history, location, setData }) => {
  const isRegionsTable = location.pathname === '/report';

  const [sortOrder, setSortOrder] = useState({
    column: null,
    direction: null,
  });

  const toggleSorting = (column) => {
    const direction = sortOrder.direction === 'asc' ? 'desc' : 'asc';

    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
    setSortOrder({ column, direction });
  };

  const columns: {
    key: string;
    label: ReactNode | string;
    width?: string;
  }[] = [
    {
      key: 'name',
      label: <TableSortableHeaderCell
        column="name" toggleSorting={toggleSorting} activeColumn={sortOrder.column} activeDirection={sortOrder.direction}
        >
        {isRegionsTable ? 'Region' : 'Office'}
      </TableSortableHeaderCell>,
      width: '10rem',
    },
    {
      key: 'employeesAmount',
      label: <TableSortableHeaderCell
        column="employeesAmount" toggleSorting={toggleSorting} activeColumn={sortOrder.column} activeDirection={sortOrder.direction}
      >
        {isRegionsTable ? 'Employees amount in region' : 'Employees amount in office'}
      </TableSortableHeaderCell>,
    },
    {
      key: 'committedEmployeesAmount',
      label: <TableSortableHeaderCell
        column="committedEmployeesAmount"
        toggleSorting={toggleSorting}
        activeColumn={sortOrder.column}
        activeDirection={sortOrder.direction}
      >
        Committed employees
      </TableSortableHeaderCell>,
    },
    {
      key: 'percentOfActiveEmployees',
      label: <TableSortableHeaderCell
        column="percentOfActiveEmployees"
        toggleSorting={toggleSorting}
        activeColumn={sortOrder.column}
        activeDirection={sortOrder.direction}
      >
        {isRegionsTable ? '% of active employees in region' : '% of active employees in office'}
      </TableSortableHeaderCell>,
    },
    {
      key: 'waterReductionTotal',
      label: <TableSortableHeaderCell
        column="waterReductionTotal" toggleSorting={toggleSorting} activeColumn={sortOrder.column} activeDirection={sortOrder.direction}
      >
        {`Water reduction (avg per employee / total in ${isRegionsTable ? 'region' : 'office'})`}
      </TableSortableHeaderCell>,
    },
    {
      key: 'energyReductionTotal',
      label: <TableSortableHeaderCell
        column="energyReductionTotal" toggleSorting={toggleSorting} activeColumn={sortOrder.column} activeDirection={sortOrder.direction}
      >
        {`Electricity reduction (avg per employee / total in ${isRegionsTable ? 'region' : 'office'})`}
      </TableSortableHeaderCell>,
    },
    {
      key: 'wasteReductionTotal',
      label: <TableSortableHeaderCell
        column="wasteReductionTotal" toggleSorting={toggleSorting} activeColumn={sortOrder.column} activeDirection={sortOrder.direction}
      >
        {`Waste reduction (avg per employee / total in ${isRegionsTable ? 'region' : 'office'})`}
      </TableSortableHeaderCell>,
    },
    {
      key: 'kgcO2eReductionTotal',
      label: <TableSortableHeaderCell
        column="kgcO2eReductionTotal" toggleSorting={toggleSorting} activeColumn={sortOrder.column} activeDirection={sortOrder.direction}
      >
        {`Emissions reduction (avg per employee / total in ${isRegionsTable ? 'region' : 'office'})`}
      </TableSortableHeaderCell>,
    },
  ];

  const handleRowClick = (reportItem: IReportItem) => {
    if (isRegionsTable) {
      history.push({
        pathname: generatePath(paths.reportOffices, { region: reportItem.name }),
        state: { reportItem },
      });
    }
  }

  return <ScrollContainer className={className}>
    <Table>
      <TableHead columns={columns} tableId="report" />
      <LoadableTableBody
        loading={loading}
        noResult={!data?.length}
        customEmptyState={<EmptyTableState colSpan={1} icon={<></>} title="No report" />}
      >
        {data?.map((item, index) => {
        return (
          <TableRow key={index} onClick={() => handleRowClick(item)} className={classNames({
            'report-table__row__isClickable': isRegionsTable,
          })}>
            <TableCell className="report-table__office">
              {item.name}
            </TableCell>
            <TableCell>{item.employeesAmount}</TableCell>
            <TableCell>{item.committedEmployeesAmount}</TableCell>
            <TableCell>{Number(item.percentOfActiveEmployees.toFixed(2)) * 100 + '%'}</TableCell>
            <TableCell>
              <ReportImpactCard type={ActivityType.Water} reductionNumber={item.waterReductionAverage} />
              <ReportImpactCard type={ActivityType.Water} reductionNumber={item.waterReductionTotal} />
            </TableCell>
            <TableCell>
              <ReportImpactCard type={ActivityType.Energy} reductionNumber={item.energyReductionAverage} />
              <ReportImpactCard type={ActivityType.Energy} reductionNumber={item.energyReductionTotal} />
            </TableCell>
            <TableCell>
              <ReportImpactCard type={ActivityType.Waste} reductionNumber={item.wasteReductionAverage} />
              <ReportImpactCard type={ActivityType.Waste} reductionNumber={item.wasteReductionTotal} />
            </TableCell>
            <TableCell>
              <ReportImpactCard type={ActivityType.Emissions} reductionNumber={item.tcO2eReductionAverage} />
              <ReportImpactCard type={ActivityType.Emissions} reductionNumber={item.tcO2eReductionTotal} />
            </TableCell>
          </TableRow>
        );
      })}
      </LoadableTableBody>
    </Table>
  </ScrollContainer>;
}