import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './EmptyState.scss';

interface EmptyStateProps {
  icon: ReactNode;
  title: string;
  text?: string;
  button?: ReactNode;
  className?: string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({ icon, title, text, button, className }) => {
  const emptyStateClass = 'empty-state';

  return (
    <div className={classNames(emptyStateClass, className)}>
      <div className={`${emptyStateClass}__icon`}>{icon}</div>
      <div className={`${emptyStateClass}__title`}>{title}</div>
      {text && <div className={`${emptyStateClass}__text`}>{text}</div>}
      {button}
    </div>
  );
};
