import React, { createContext, useState } from 'react';
import { ActivityType } from 'src/modules/activities/domain';
import { Medal } from 'src/modules/impact/domain';

export const MobileImpactProgressBarViewProviderContext = createContext<[MobileImpactProgressBarViewContextValue,
  (value: Partial<MobileImpactProgressBarViewContextValue>) => void,
  (value: MobileImpactProgressBarViewContextValue) => void
]>([
  {} as MobileImpactProgressBarViewContextValue,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

export interface MobileImpactProgressBarViewContextValue {
  isOpen: boolean;
  sentencesWithUnderstandableUnits: string[];
  activityType: ActivityType;
  unitsLeftToNextLevel: number;
  medalAchived: Medal;
}

const MobileImpactProgressBarViewProvider = ({ children }) => {
  const [config, setConfig] = useState<MobileImpactProgressBarViewContextValue>({
    isOpen: false,
    sentencesWithUnderstandableUnits: [],
    activityType: null,
    unitsLeftToNextLevel: 0,
    medalAchived: null,
  });

  const updateConfig = (update: Partial<MobileImpactProgressBarViewContextValue>) => setConfig((prev) => ({ ...prev, ...update }));

  return (
    <MobileImpactProgressBarViewProviderContext.Provider value={[config, updateConfig, setConfig]}>
      {children}
    </MobileImpactProgressBarViewProviderContext.Provider>
  );
};
export default MobileImpactProgressBarViewProvider;
