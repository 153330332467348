/* eslint-disable max-len */
import { useCallback, useContext, useEffect } from 'react';
import { activityService } from '../services';
import { ITEMS_PER_PAGE } from 'src/consts';
import { useFetch } from 'src/hooks';
import { ActivitiesTab } from '../consts';
import { MobileActivitiesFiltersContext } from 'src/providers';

export const useActivities = (tab: ActivitiesTab = ActivitiesTab.All) => {
  const {
    toggleFilters,
    filters,
    setFilters,
    updateFilters,
    activities,
    setActivities,
    loaded,
    setLoaded,
    offset,
    setOffset,
    reset,
  } = useContext(MobileActivitiesFiltersContext);

  const fetchActivities = useCallback(() => {
    return activityService.list({
      ...filters,
      pageSize: ITEMS_PER_PAGE,
      pageNumber: offset,
      isUserActivities: tab === ActivitiesTab.My,
    });
  }, [filters, offset, tab]);

  const [{ data, loading }, refetch] = useFetch(fetchActivities, { autoFetch: false });

  const loadActivities = useCallback(async () => {
    await refetch();
    setOffset((offset) => offset + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
    if (data?.data.length) {
      setActivities([...activities, ...data.data]);
      setLoaded(data.data.length < ITEMS_PER_PAGE);
    } else {
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  const removeActivity = useCallback(
    (activityId: number) => {
      setActivities(activities.filter((activity) => activity.activityId !== activityId));
    },
    [activities, setActivities],
  );

  return {
    activities,
    loading,
    loaded,
    loadActivities,
    reset,
    filters,
    updateFilters,
    setFilters,
    toggleFilters,
    removeActivity,
  };
};
