import { useCallback, useContext, useEffect, useState } from 'react';
import { ActivityReqParams } from '../domain';
import { AuthContext } from 'src/providers';
import { administrationService } from '../services/administrationActivity';
import { IActivity } from 'src/types';

const fetchAdminActivities = async (params) => {
  const data = await administrationService.getActivities(params);
  return data;
};

const postAdminActivity = async (activity: Partial<IActivity>) => {
  // remove empty fields
  const transformedActivity = Object.fromEntries(
    Object.entries(activity).filter(([key, value]) => value !== undefined && value !== ''),
  );
  const data = await administrationService.postActivity(transformedActivity);
  return data;
};

const putAdminActivity = async (activity) => {
  // remove empty fields
  const transformedActivity = Object.fromEntries(
    Object.entries(activity).filter(([key, value]) => value !== undefined && value !== ''),
  );
  const data = await administrationService.putActivity(transformedActivity);
  return data;
};

export const useAdministrationActivities = (filters) => {
  const [isActivitiesLoading, setIsLoading] = useState(false);
  const [activitiesList, setActivitiesList] = useState<any>([]);

  const fetch = useCallback(async (params = filters) => {
    setIsLoading(true);
    const activities = await fetchAdminActivities(params);
    setActivitiesList(activities.data);
    setIsLoading(false);
  }, []);

  async function addActivity(activity) {
    await postAdminActivity(activity);
    await fetch();
  }

  async function updateActivity(activity) {
    await putAdminActivity(activity);
    await fetch();
  }

  async function deleteActivity(activity) {
    await administrationService.deleteActivity(activity);
    await fetch();
  }

  async function hideActivity(activity) {
    await administrationService.hideActivity(activity);
    await fetch();
  }

  async function unhideActivity(activity) {
    await administrationService.unhideActivity(activity);
    await fetch();
  }

  async function reset() {
    setActivitiesList(null);
    await fetch();
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWithFilters = useCallback(
    async (params?) => {
      fetch(params);
    },
    [fetch],
  );

  return {
    isActivitiesLoading,
    activitiesList,
    addActivity,
    updateActivity,
    deleteActivity,
    hideActivity,
    unhideActivity,
    fetch: fetchWithFilters,
    reset,
  };
};
