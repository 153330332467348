import { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import paths from "src/routes/paths";

import { ReportHeader, ReportTable } from "./components";
import { IReportItem } from "./domain";
import { useReport } from "./hooks";

import './ReportPage.scss';

function ilike(str, substr) {
  return str.toLowerCase().includes(substr.toLowerCase());
}

const className = 'report-page';

export const ReportPage = () => {
  const {
    report,
    loading,
    filters,
    updateFilters
  } = useReport();

  const location = useLocation();

  const [data, setData] = useState<IReportItem[]>([]);

  useEffect(() => {
    if (location.pathname === paths.report) {
      if (filters.search.length) {
        return setData(report.filter(({ name }) => ilike(name, filters.search)));
      }
      return setData(report);
    } else {
      const regex = /\/report\/(.*?)\/offices/;
      const region = location.pathname.match(regex)[1];
      const offices = report.find((item) => item.name === region)?.sublevels || [];

      if (filters.search.length) {
        return setData(offices.filter(({ name }) => ilike(name, filters.search)));
      }
      return setData(offices);
    }
  }, [filters.search, location.pathname, report])

  return <div className={className}>
    <ReportHeader filters={filters} updateFilters={updateFilters} regions={report.map(item => item.name)} />
    <Switch>
      <Route path={paths.report} render={(props) => <ReportTable
        {...props}
        data={data}
        setData={setData}
        loading={loading}
      />} />
      <Route path={paths.reportOffices} render={(props) => <ReportTable
        {...props}
        data={data}
        setData={setData}
        loading={loading}
      />} />
    </Switch>
  </div>
}