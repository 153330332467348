import React, { useCallback, useRef } from 'react';
import { Button, Footer, Header } from 'src/components';
import { useViewport } from 'src/hooks';

import './MainPage.scss';

export const MainPage: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const scrollToContainerRef = useRef(null);
  const mainPageRef = useRef(null);
  const { isDesktop } = useViewport();

  const scrollToTop = useCallback(() => {
    scrollToContainerRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div className="main-page">
      <Header />
      <main className="main-page__main">
        <div ref={scrollToContainerRef} />
        <div className="main-page__content" ref={mainPageRef}>{children}</div>
        { isDesktop && <Button
          className="scroll-to-top-btn"
          onClick={scrollToTop}
          fullWidth={false}
        >▲ Scroll to top</Button> }
        <Footer />
      </main>
    </div>
  );
};
