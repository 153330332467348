import { ActivityType } from 'src/modules/activities/domain';
import { ImpactMedalsLevelPerType } from '../domain';

export const impactLevelsPerType: ImpactMedalsLevelPerType = {
  [ActivityType.Water]: {
    maxValue: 569_924,
    medalsMinValues: {
      bronze: 10_000,
      silver: 50_000,
      gold: 250_000,
    },
  },
  [ActivityType.Energy]: {
    maxValue: 27_290,
    medalsMinValues: {
      bronze: 3_000,
      silver: 7_000,
      gold: 15_000,
    },
  },
  [ActivityType.Waste]: {
    maxValue: 185,
    medalsMinValues: {
      bronze: 20,
      silver: 60,
      gold: 120,
    },
  },
  [ActivityType.Emissions]: {
    maxValue: 13_851,
    medalsMinValues: {
      bronze: 2_500,
      silver: 4_000,
      gold: 10_000,
    },
  },
  // [ActivityType.Inclusion]: {
  //   maxValue: 13_851,
  //   medalsMinValues: {
  //     bronze: 2_500,
  //     silver: 4_000,
  //     gold: 10_000,
  //   },
  // },
  // [ActivityType.Community]: {
  //   maxValue: 13_851,
  //   medalsMinValues: {
  //     bronze: 2_500,
  //     silver: 4_000,
  //     gold: 10_000,
  //   },
  // },
};
