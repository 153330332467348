import axios, { AxiosRequestConfig } from 'axios';
import { loginRequest } from 'src/auth/authConfig';
import { msalInstance } from 'src/auth/Security';

declare module 'axios' {
  interface AxiosRequestConfig {
    errorMessage?: string;
    successMessage?: string;
  }
}

export interface AxiosResponse<T = never> {
  data: T;
  status: number;
  statusText: string;
  headers: Record<string, string>;
  config: AxiosRequestConfig;
  request?: any;
}

// API Client factory
const createAPIClient = ({ basePath, ...axiosConfig }) => {
  const baseUrl =
    window.location.hostname === 'localhost'
      ? 'https://esgprojectbackenddev.azurewebsites.net' //https://localhost:7224
      : window.location.origin;

  const api = axios.create({
    baseURL: `${baseUrl}/api/${basePath}`,
    headers: {},
    ...axiosConfig,
  });

  api.interceptors.request.use(async (config) => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    config.headers['Authorization'] = `Bearer ${response.idToken}`;
    config.headers['Authorization2'] = `Bearer ${response.accessToken}`;

    return config;
  });

  api.interceptors.response.use(
    (response) => {
      if (response.config.successMessage) {
        console.log(response.config.successMessage);
      }
      return response;
    },
    (error) => {
      if (axios.isAxiosError(error) && error.config.errorMessage) {
        console.error(error.config.errorMessage);
      }
      return Promise.reject(error);
    },
  );

  return api;
};

export default createAPIClient;
