import React from 'react';
import classNames from 'classnames';

import './ScrollContainer.scss';

interface IScrollContainer {
  children: React.ReactNode;
  className?: string;
}

const ScrollContainer: React.FC<IScrollContainer> = ({ children, className = '' }) => (
  <div className={classNames('scroll-container', className)}>{children}</div>
);

export default ScrollContainer;
