import React from 'react';

import './FAQ.scss';

export const FAQ: React.FC = () => {

  return (
    <div className="faq__container">
      {/*<iframe className="faq" src="https://dentonsglobal.sharepoint.com/sites/sustainability-sbx/SitePages/FAQs.aspx" />*/}
      <h1 className="faq__main_title">
        FAQs
      </h1>
      <br />
      <div data-automation-id="textBox" className="ck-content rteEmphasis faq__root-278">
        <p>
          <span className="faq__fontSizeSuper">
            <span className="faq__fontColorGreen">
              <strong>
                A Million Positive Impacts
              </strong>
            </span>
          </span>
        </p>
        <br />
        <p>
          <span className="faq__fontSizeXxxLarge">
            <span className="faq__fontColorPurple">
              <strong>
                Frequently asked questions
              </strong>
            </span>
          </span>
        </p>
        <br />
        <p>
          <strong>
            What is “A Million Positive Impacts” about?
          </strong>
          <br />
          Climate change is the biggest challenge of our time. Over 80% of young people are worried about the climate, and rightly so.
        </p>
        <p>
          Many of us understand that if we are to stop climate change, massive action is required, affecting all parts of
          our lives. Individuals, organizations, companies, and policymakers are now steering towards the&nbsp;
          <a href="https://unfccc.int/process-and-meetings/the-paris-agreement/the-paris-agreement">
            Paris Agreement goals
          </a>
          &nbsp;and beyond.&nbsp; But the shift must come faster, and as individuals, we have the power as
          both consumers and citizens. We make lots of choices every day about what and how we consume, and where to steer
          our attention.
        </p>
        <p>
          Active and passive choices made daily affect our carbon footprint and thus the planet we call home. But finding
          and living&nbsp;a sustainable lifestyle&nbsp;is not easy, but&nbsp;with learnings, insights, and millions of
          small, positive actions, we can do it together.&nbsp;
        </p>
        <p>
          The world needs more climate action, so we wanted to make it as easy as possible for everyone to see the positive
          changes they can make, commit to making them, and then see the impact of those commitments.
        </p>
        <p>
          <strong>
            What is the main aim of the site?
          </strong>
          <br />
          The aim is to encourage everyone to take steps to cut their
          environmental footprint. If we continue business as usual, the world is on track to heat up by 3c at least by
          the end of this century. At current global emissions rates, the carbon budget that we have left if we are to
          stay under 1.5c will be depleted in six years. It&apos;s a big task but a million positive impacts can help you with
          the steps you need to take to get there.
        </p>
        <p>
          <strong>
            Who is it for?
          </strong>
          <br />
          It is for everybody, everywhere, in Dentons! A lot of people are wondering
          what they can do about environmental issues such as climate change, biodiversity conservation and pollution but
          do not know where to start. This tool is designed for you and those who are already making sustainable and
          environmentally friendly choices in their lives and want more ideas to help them go further. By signing up
          you&apos;ll be able to start doing whatever you can, as soon as you can!
        </p>
        <p>
          <strong>
            Do my individual actions really matter?
          </strong>
          <br />
          Absolutely, positively, YES!&nbsp; Science tells us
          that if we want to save the planet then each and every one of us urgently needs to do everything that we
          can.&nbsp; There are easy and difficult actions, and there are low impact and high impact actions. There are
          also long-term and short-term actions.&nbsp; ALL of them are positive actions, and together they will make the
          world a better place.
        </p>
        <p>
          Some actions have a direct&nbsp;carbon emission reduction, some save water or electricity, and some focus on
          reducing the amount of waste we produce.&nbsp; All of these actions, large or small, matter. We need all of our
          combined energy, efforts, and ideas to solve this…. so please start where you can, and commit to what you can….
          but start today.
        </p>
        <p>
          <strong>
            Why do it?
          </strong>
          <br />
          There&apos;s no beating around the bush on this one... it is reported that global
          carbon emissions are still on an increase therefore increasing world temperatures. Animals are under threat from
          extinction and losing habitats. People around the world are already being affected by extreme weather events
          including floods, droughts, fires and rising sea levels. We all need to live more sustainably and move towards a
          thriving, and sustainable planet.
        </p>
        <p>
          <strong>
            How does it work?
          </strong>
          <br />
          “A Million Positive Impacts” provides a long list of environmentally
          positive activities that can be filtered different ways, and which you can then commit to in order to reduce
          your environmental footprint. It helps you to understand your personal footprint by providing a view of your
          impact for each activity you commit to. The methodology used to calculate the impact can be found here.
        </p>
        <p>
          <strong>
            What about data privacy? How protected and secure is my personal information?
          </strong>
          <br />
          Dentons is committed to protecting your personal data and respecting your privacy when accessing and using
          the ESG Application for the purpose of encouraging you to become more ESG/sustainability minded by taking part
          in a number of ESG-focused activities.  Further information on how we process your personal data can be
          found in the&nbsp;
          <a href="/privacy_notice_esg_app.pdf" target="_blank">
            ESG Application Privacy Notice.
          </a>
        </p>
        <p>
          <strong>
            I predominantly work from home.... does it make a difference?
          </strong>
          <br />
          Yes. Whether you work from
          your home or office building, there are many activities you can commit to. In fact, the site is designed to account
          for all the positive impacts throughout our daily lives.
        </p>
        <p>
          <strong>
            Can I discontinue an activity at any point?
          </strong>
          <br />
          Of course! We are proving these activities to
          help show you what is possible, but nobody is &apos;policing&apos; your commitments.&nbsp; Participation is voluntary
          therefore no one is obligated to continue doing anything they no longer wish to do. We however encourage users
          to stick it out and contribute their share towards protecting the environment.&nbsp; If you really want to, you
          can also click the remove button to register that you will discontinue doing a particular activity.
        </p>
        <p>
          <strong>
            Can I unsubscribe from&nbsp;regular updates?
          </strong>
          <br />
          Yes. You can use the unsubscribe link at the
          bottom of the page to unsubscribe from regular email updates.
        </p>
        <p>
          <strong>
            Who else can I share it with?
          </strong>
          <br />
          Everybody!&nbsp;Everywhere! (within Dentons anyway). You can
          share it with any of your colleagues to encourage them to sign up and commit.&nbsp; Challenge or encourage
          (whichever motivational strategy works best!) your team, your colleagues, or your office to &apos;compete&apos; against
          others to see who can make the biggest positive impact.
        </p>
        <p>
          <strong>
            How will I know my impact?
          </strong>
          <br />
          Your impact is calculated based on the different activities you
          commit to and displayed on the landing page of the tool. You can also click on the impact icon to view more
          details regarding impact.&nbsp; We have researched the data for each action, and used commonly available metrics
          to calculate the impact.&nbsp; Most of the data comes from either the US, or Europe - but every action has the
          same impact calculated no matter where you are - so every positive action is &apos;equal&apos; across the firm.
        </p>
        <p>
          <span className="fontSizeXSmall" />
        </p>
        <br />
        <p>
          <span className="faq__fontSizeXxxLarge">
            <span className="faq__fontColorPurple">
              <strong>
                Some useful questions and links to responses
              </strong>
            </span>
          </span>
        </p>
        <br />
        <p>
          <span className="faq__fontColorPurple">
            All links will open in a new tab in your browser.
          </span>
          &nbsp; The questions in
          this&nbsp;section&nbsp;all link to different external websites over which we have no responsibility - we provide
          these links simply for you to do your own research.
        </p>
        <figure className="faq__table canvasRteResponsiveTable" style={{ width: 1104 }} title="Table">
          <table className="faq__filledHeaderTableStyleTheme ck-table-resized">
            <colgroup>
              <col style={{ width: '100%' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <strong>
                    Climate Change
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.epa.gov/climatechange-science/frequently-asked-questions-about-climate-change#climate-change">
                    <u>
                      What is climate change?
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.epa.gov/climatechange-science/frequently-asked-questions-about-climate-change#cold-snowfall">
                    <u>
                      Why has my town experienced record-breaking cold and snowfall if the climate is warming?
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.epa.gov/climatechange-science/frequently-asked-questions-about-climate-change#degree">
                    <u>
                      Why be concerned about a degree or two rise in global temperature?
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.epa.gov/climatechange-science/frequently-asked-questions-about-climate-change#health">
                    <u>
                      How does climate change affect people`&apos;s health?
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.epa.gov/climatechange-science/frequently-asked-questions-about-climate-change#risk">
                    <u>
                      Who is most at risk from the impacts of climate change?
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.epa.gov/climatechange-science/frequently-asked-questions-about-climate-change#reduce-risks">
                    <u>
                      How can people reduce the risks of climate change?
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.epa.gov/climatechange-science/frequently-asked-questions-about-climate-change#benefits-action">
                    <u>
                      What are the benefits of taking action now?
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.epa.gov/ghgemissions/overview-greenhouse-gases">
                    <u>
                      What are greenhouse gases?
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.epa.gov/climate-change/what-you-can-do-about-climate-change">
                    <u>
                      What other actions can I take to reduce my share of impact?
                    </u>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </figure>
        <p>
            &nbsp;
        </p>
        <figure className="faq__table canvasRteResponsiveTable" style={{ width: 1104 }} title="Table">
          <table className="faq__filledHeaderTableStyleTheme ck-table-resized">
            <colgroup>
              <col style={{ width: '100%' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <strong>Energy</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.conserve-energy-future.com/Advantages_SolarEnergy.php">
                    <u>
                      Why solar energy?
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.calculator.net/electricity-calculator.html?appliance=light:7:W:8:hpd:100&amp;power=7&amp;powerunit=kW&amp;capacity=100&amp;usage=8&amp;usageunit=hpd&amp;price=0.15&amp;x=43&amp;y=27">
                    <u>
                      Want to know how much electricity you use per appliance? Click of the Electricity Calculator to find out!
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.energysage.com/energy-efficiency/101/ways-to-save-energy/#:~:text=1%201.%20Adjust%20your%20day-to-day%20behaviors%20...%202%2c8.%20Upgrade%20your%20HVAC%20system%20...%20More%20items">
                    <u>
                      What other actions can I take conserve energy?
                    </u>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </figure>
        <p>
          &nbsp;
        </p>
        <figure className="faq__table canvasRteResponsiveTable" style={{ width: 1104 }} title="Table">
          <table className="faq__filledHeaderTableStyleTheme ck-table-resized">
            <colgroup>
              <col style={{ width: '100%' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <strong>
                    Water
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.worldvision.org/clean-water-news-stories/global-water-crisis-facts#facts">
                    <u>
                      Fast facts: Global water crisis
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.unwater.org/water-facts/water-scarcity">
                    <u>
                      What is water Scarcity?&nbsp;
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.seametrics.com/blog/global-water-crisis-facts/">
                    <u>
                      25 facts you should know about the Global water crisis
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://thewatergeeks.com/water-shortage-causes-solutions/#Solutions_for_Water_Shortages">
                    <u>
                      What other actions can I take to reduce my share of impact?
                    </u>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </figure>
        <p>&nbsp;</p>
        <figure className="faq__table canvasRteResponsiveTable" style={{ width: 1104 }} title="Table">
          <table className="faq__filledHeaderTableStyleTheme ck-table-resized">
            <colgroup>
              <col style={{ width: '100%' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <strong>
                    Waste
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.conserve-energy-future.com/various-waste-disposal-problems-and-solutions.php">
                    <u>
                      What are some of the various waste disposal problems?
                    </u>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.conserve-energy-future.com/super-easy-ways-to-adopt-zero-waste-lifestyle.php">
                    <u>
                      What other actions can I take to reduce my share of impact?
                    </u>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </figure>
        <p>
          &nbsp;
        </p>
        <p>
          &nbsp;
        </p>
        <p>
          &nbsp;
        </p>
      </div>
    </div>
  );
};
