import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ArrowLeftIcon } from 'src/assets/icons';
import { Button, SingleSelect } from 'src/components';
import SearchInput from 'src/components/UI/SearchInput';
import { useDebounce } from 'src/hooks';
import { activityService } from 'src/modules/activities/services';
import paths from 'src/routes/paths';
import { GetReportReqParams } from '../../domain';

import './ReportHeader.scss';

const className = 'report-header';

type ReportHeaderProps = {
  filters: GetReportReqParams;
  updateFilters: (filters: Partial<GetReportReqParams>) => void;
  regions: string[];
};

export const ReportHeader: React.FC<ReportHeaderProps> = ({ filters, updateFilters, regions }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const regionsOptions = useMemo(
    () =>
      regions.map((region) => ({
        value: region,
        label: region,
      })),
    [regions],
  );

  const delayedDispatchSearch = useDebounce((search: string) => {
    updateFilters({ search: search.length > 2 || !search.length ? search : '' });
  }, 500);

  const exportLinkRef = useRef(null);

  const downloadReport = async () => {
    try {
      setIsDownloading(true);
      const blob = await activityService.getCSVReport();

      const objectUrl = window.URL.createObjectURL(blob);

      exportLinkRef.current.href = objectUrl;
      exportLinkRef.current.download = 'activities-report.xlsx';
      exportLinkRef.current.click();

      window.URL.revokeObjectURL(objectUrl);
    } finally {
      setIsDownloading(false);
    }
  };

  const history = useHistory();
  const location = useLocation();

  const activeRegion = useMemo(() => {
    const regex = /\/report\/(.*?)\/offices/;
    const region = location.pathname.match(regex)?.[1];
    return region ?? undefined;
  }, [location.pathname]);

  const handleSelectRegion = (region: string) => {
    history.replace(paths.reportOffices.replace(':region', region));
  };

  const isOfficesPage = location.pathname !== paths.report;

  const searchRef = useRef(null);

  useEffect(() => {
    if (filters.search === '') {
      searchRef.current?.clearHandler();
    }
  }, [filters.search]);

  return (
    <div className={className}>
      {isOfficesPage && (
        <Button className={`${className}__back-btn`} fullWidth={false} onClick={() => window.history.back()}>
          <ArrowLeftIcon /> Regions
        </Button>
      )}
      <h1>{isOfficesPage ? 'Offices' : 'Regions'}</h1>
      <a ref={exportLinkRef} className={`${className}__export-link`} />
      <SearchInput
        initialValue={filters.search}
        onChange={delayedDispatchSearch}
        className={`${className}__search-input`}
        ref={searchRef}
      />
      {isOfficesPage && (
        <SingleSelect
          className={`${className}__select`}
          name="region"
          placeholder="Choose region"
          options={regionsOptions}
          value={activeRegion}
          onChange={handleSelectRegion}
        />
      )}
      <Button
        className={`${className}__download-report-btn`}
        fullWidth={false}
        onClick={downloadReport}
        disabled={isDownloading}
      >
        {isDownloading ? 'Exporting...' : 'Export Detailed Report'}
      </Button>
    </div>
  );
};
