export interface IActivity {
  activityId: number;
  locationOrActivity: string; //deprecated
  isWater: boolean;
  isEnergy: boolean;
  isWaste: boolean;
  isEmissions: boolean;
  isInclusion: boolean;
  isCommunity: boolean;
  difficulty: string;
  impact: string;
  // difficulty: ActivityDifficulty;
  // impact: ActivityImpact;
  positiveAction: string; // action's display name
  description: string;
  energyReduction: number;
  waterReduction: number;
  wasteReduction: number;
  tcO2eReduction: number;
  inclusionReduction: number;
  communityReduction: number;
  notes: string;
  sources: string;
  isHidden: boolean;
  locationId: number;
  locations: [
    {
      locationId: number;
      name: string;
    },
  ];
}

export type ActivityList = IActivity[];

export enum ActivityDifficulty {
  Easy = 'Easy',
  Medium = 'Medium',
  Hard = 'Hard',
}

export enum ActivityImpact {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export enum ActivityType {
  Water = 'Water',
  Energy = 'Energy',
  Waste = 'Waste',
  Emissions = 'Emissions',
  // Inclusion = 'Inclusion',
  // Community = 'Community',
}

export enum ActivityLocation {
  Bathroom = 'Bathroom',
  Clothing = 'Clothing',
  DIY = 'DIY',
  Garden = 'Garden',
  Home = 'Home',
  Kitchen = 'Kitchen',
  Leisure = 'Leisure',
  Office = 'Office',
  Recycling = 'Recycling',
  Shopping = 'Shopping',
  Transportation = 'Transportation',
}
