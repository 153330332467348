import { IReportItem } from 'src/modules/report/domain';
import { BaseService } from 'src/services/base-service';
import { RequestQueryParams } from 'src/types';
import { ActivitiesResponse, ActivityList, ActivityReqParams, UserActivitiesImpact } from '../domain';

class AdministrationActivityService extends BaseService {
  constructor() {
    super({ basePath: 'Activities' });
  }

  async list(options: Partial<ActivityReqParams> & Omit<RequestQueryParams, 'sortOrder'>) {
    const params = this.getQueryParams(options);
    const response = await this.api.get<{ data: ActivityList }>(`/get-filtered`, { params });

    return response;
  }

  async getActivities(options) {
    const params = this.getQueryParams(options);
    const response = await this.api.get<ActivitiesResponse>(`/get-filtered`, { params });
    return response.data;
  }

  async postActivity(activity) {
    const response = await this.api.post(`/insert`, { ...activity });
    return response.data;
  }

  async putActivity(activity) {
    const response = await this.api.put(`/update`, { ...activity });
    return response.data;
  }

  async hideActivity(activities) {
    const response = await this.api.put(`/isHidden/true`, [...activities]);
    return response.data;
  }

  async unhideActivity(activities) {
    const response = await this.api.put(`/isHidden/false`, [...activities]);
    return response.data;
  }

  async deleteActivity(activityId) {
    const response = await this.api.delete(`/delete`, { data: activityId });
    return response.data;
  }
}

export const administrationService = new AdministrationActivityService();
