import React from 'react';
import classNames from 'classnames';

import './Ellipsis.scss';

export enum EllipsisColor {
  Green = 'green',
  Blue = 'blue'
}

export type EllipsisProps = {
  filled: boolean;
  color: EllipsisColor;
}

const className = 'ellipsis';

export const Ellipsis: React.FC<EllipsisProps> = ({ filled, color }) => {
  return <div className={classNames(className, {
    [`${className}--${color}`]: filled
  })} />;
}
