import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function LocationDialog({ open, handleClose, onSave, onDisagree }) {
  //   const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (e.target.validity.valid) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };

  const handleSave = () => {
    if (!name) {
      setNameError(true);
      return;
    }
    handleClose();
    onSave?.(name);
  };

  const handleDisadgree = () => {
    handleClose();
    onDisagree?.();
  };

  return (
    <React.Fragment>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>New Location</DialogTitle>
        <DialogContent>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={handleNameChange}
            error={nameError}
            helperText={nameError ? 'Please enter location name' : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisadgree} color="error" variant="contained">
            Cancel
          </Button>
          <Button disabled={nameError} onClick={handleSave} color="success" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
