import classNames from 'classnames';
import React, { useCallback, useContext, useMemo } from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

import {
  InfoIcon,
  EmissionsIcon,
  WasteIcon,
  ElectricityIcon,
  WaterIcon,
  BronzeMedal,
  SilverMedal,
  GoldMedal,
  InclusionIcon,
  CommunityIcon,
} from 'src/assets/icons';

import { Tooltip } from 'src/components';
import { ActivityType } from 'src/modules/activities/domain';
import { Position as TooltipPosition } from 'src/components/UI/Tooltip/Tooltip';
import { Medal } from 'src/modules/impact/domain';
import { MobileImpactProgressBarViewProviderContext } from 'src/providers';

import { ContentInProgressBar } from '../../tooltip-contents';

import './ImpactProgressBar.scss';

export type ImpactProgressBarProps = {
  type: ActivityType;
  percent: number;
  reductionNumber: number;
  stepPositionsInPercents: number[];
  medalAchived: Medal | null;
  tooltipData: string[];
  unitsLeftToNextLevel: number | null;
};

type CardConfig = {
  style: string;
  filledBackground: string;
  Icon: () => JSX.Element;
  unit: string;
  tooltipPosition: TooltipPosition;
};

const className = 'impact-progress-bar';

export const ImpactProgressBar: React.FC<ImpactProgressBarProps> = ({
  type,
  reductionNumber,
  percent,
  stepPositionsInPercents,
  medalAchived,
  tooltipData,
  unitsLeftToNextLevel,
}) => {
  const cardConfig: CardConfig = {
    [ActivityType.Water]: {
      style: 'waterType',
      filledBackground: '#0073B9',
      Icon: WaterIcon,
      unit: 'l/year',
      tooltipPosition: TooltipPosition.leftBottom,
    },
    [ActivityType.Energy]: {
      style: 'energyType',
      filledBackground: '#F5B800',
      Icon: ElectricityIcon,
      unit: 'kWh/year',
      tooltipPosition: TooltipPosition.bottom,
    },
    [ActivityType.Waste]: {
      style: 'wasteType',
      filledBackground: '#F74E31',
      Icon: WasteIcon,
      unit: 'Level',
      tooltipPosition: TooltipPosition.bottom,
    },
    [ActivityType.Emissions]: {
      style: 'emissionType',
      filledBackground: '#56AB2F',
      Icon: EmissionsIcon,
      unit: 'kg CO2e/year',
      tooltipPosition: TooltipPosition.rightBottom,
    },
    // [ActivityType.Inclusion]: {
    //   style: 'inclusionType',
    //   filledBackground: '#e783e9',
    //   Icon: InclusionIcon,
    //   unit: 'Level',
    //   tooltipPosition: TooltipPosition.bottom,
    // },
    // [ActivityType.Community]: {
    //   style: 'communityType',
    //   filledBackground: '#83c7e9',
    //   Icon: CommunityIcon,
    //   unit: 'Level',
    //   tooltipPosition: TooltipPosition.bottom,
    // },
  }[type];

  const [config, updateConfig] = useContext(MobileImpactProgressBarViewProviderContext);

  const toggleMobileView = useCallback(() => {
    updateConfig({
      isOpen: !config.isOpen,
      sentencesWithUnderstandableUnits: tooltipData,
      activityType: type,
      unitsLeftToNextLevel,
      medalAchived,
    });
  }, [config.isOpen, medalAchived, tooltipData, type, unitsLeftToNextLevel, updateConfig]);

  const { style, Icon, unit, filledBackground, tooltipPosition } = cardConfig;

  const metrics = useMemo(() => {
    const finalReductionNumber = Math.round(reductionNumber).toLocaleString();
    let result = `${finalReductionNumber} ${unit}`;

    if (type === ActivityType.Waste) {
      result = `${unit} ${finalReductionNumber}`;
    }

    return result;
  }, [reductionNumber, type, unit]);

  return (
    <div className={className}>
      <Tooltip
        content={
          <ContentInProgressBar
            sentences={tooltipData}
            medalAchived={medalAchived}
            unitsLeftToNextLevel={unitsLeftToNextLevel}
            activityType={type}
          />
        }
        position={tooltipPosition}
        className={`${className}__bar`}
      >
        <ProgressBar percent={percent} filledBackground={filledBackground} stepPositions={stepPositionsInPercents}>
          <Step transition="scale">
            {() => (
              <div className={classNames(`${className}__medal`, `${className}__medal--${style}`)}>
                <BronzeMedal />
              </div>
            )}
          </Step>
          <Step transition="scale">
            {() => (
              <div className={classNames(`${className}__medal`, `${className}__medal--${style}`)}>
                <SilverMedal />
              </div>
            )}
          </Step>
          <Step transition="scale">
            {() => (
              <div className={classNames(`${className}__medal`, `${className}__medal--${style}`)}>
                <GoldMedal />
              </div>
            )}
          </Step>
        </ProgressBar>
      </Tooltip>
      <div className={classNames(`${className}__metrics`, `${className}__metrics__${style}`)}>
        <Icon />
        <div>{metrics}</div>
        <div className={`${className}__metrics__info-icon`} onClick={toggleMobileView}>
          <InfoIcon />
        </div>
      </div>
      <div className={`${className}__type`}>{type} impact</div>
    </div>
  );
};
