import React, { useCallback, useContext } from 'react';

import { MobileImpactProgressBarViewProviderContext } from 'src/providers';
import { useViewport } from 'src/hooks';
import { Button } from 'src/components';
import { Close } from 'src/assets/icons';

import { ContentWithListSection, ContentWithMedalSection } from '../../tooltip-contents';

import './MobileImpactProgressView.scss';

const className = 'mobile-impact-progress-view';

export const MobileImpactProgressView: React.FC = () => {
  const [config, setConfig] = useContext(MobileImpactProgressBarViewProviderContext);
  const onClose = useCallback(() => {
    setConfig({ isOpen: !config.isOpen });
  }, [config.isOpen, setConfig]);

  const { isDesktop } = useViewport();

  const { isOpen } = config;

  if (!isOpen || isDesktop) {
    return null;
  }

  return (
    <div className={className}>
      <div className={`${className}__body`}>
        <div className={`${className}__header`}>
          <Button className={`${className}__close`} fullWidth={false} onClick={onClose}><Close /></Button>
        </div>
        <div className={`${className}__content`}>
          <ContentWithMedalSection
            medalAchived={config.medalAchived}
            unitsLeftToNextLevel={config.unitsLeftToNextLevel}
            activityType={config.activityType}
            />
          <ContentWithListSection sentences={config.sentencesWithUnderstandableUnits} />
        </div>
      </div>
    </div>
  );
};
