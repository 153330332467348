import React, { useCallback, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { AuthContext, MobileNavScreenContext } from 'src/providers';
import { mobileLinks } from 'src/routes/header-links';
import DentonsLogo from 'src/assets/images/dentons-logo-filled-purple.png';
import GlobalSustainabilityLogo from 'src/assets/images/global-sustainability-network-filled-purple.png';
import { useViewport } from 'src/hooks';
import { Button } from 'src/components/UI';
import { ContactUs, FAQ, Suggestions } from 'src/assets/icons';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

import './MobileNavScreen.scss';
import paths from 'src/routes/paths';

const className = 'mobile-nav-screen';

const companyName = 'Dentons';
const globalSustainability = 'Global Sustainability';

type Option = {
  name: string;
  Icon: () => JSX.Element;
  className?: string;
  onClick?: () => void;
  href?: string;
  target?: string;
};

const Option: React.FC<Option> = ({ Icon, name, onClick, href, target }) => (
  <a href={href} target={target} className={`${className}__option`} onClick={onClick}>
    <div className={`${className}__option__icon`}>
      <Icon />
    </div>
    <div className={`${className}__option__name`}>{name}</div>
  </a>
);

export const MobileNavScreen: React.FC = () => {
  const [config] = useContext(MobileNavScreenContext);
  const { authState, fullname, logout: handleLogout, isAdmin } = useContext(AuthContext);
  const { isDesktop } = useViewport();
  const history = useHistory();

  const { isOpen } = config;

  const handleFAQ = useCallback(() => {
    history.push(paths.faq);
  }, [history]);

  const handleAdministration = useCallback(() => {
    history.push(paths.administration);
  }, [history]);

  const options: Option[] = [
    {
      name: 'Contact Us',
      Icon: ContactUs,
      href: 'mailto:PositiveImpacts@dentons.com?subject=A Million Positive Impacts | Contact us',
      target: '_blank',
    },
    {
      name: 'Your Suggestions',
      Icon: Suggestions,
      href: 'mailto:PositiveImpacts@dentons.com?subject=A Million Positive Impacts | Content Suggestions',
      target: '_blank',
    },
    { name: 'FAQ', Icon: FAQ, onClick: handleFAQ },
  ];

  if (isAdmin) {
    options.unshift({
      name: 'Administration',
      Icon: () => <AdminPanelSettingsOutlinedIcon />,
      onClick: handleAdministration,
    });
  }

  if (!isOpen || isDesktop) {
    return null;
  }

  return (
    <div className={className}>
      <div className={`${className}__body`}>
        <img className={`${className}__company-logo`} src={DentonsLogo} alt={companyName} />
        <img className={`${className}__product-logo`} src={GlobalSustainabilityLogo} alt={globalSustainability} />
        <nav className={`${className}__navbar`}>
          {mobileLinks.map(({ to, label }, i) => (
            <NavLink key={i} className={`${className}__navbar__navlink`} activeClassName="navlink--active" to={to}>
              {label}
            </NavLink>
          ))}
        </nav>
        <footer className={`${className}__footer`}>
          <div className={`${className}__footer__fullname`}>{fullname}</div>
          <div className={`${className}__footer__email`}>{authState?.username}</div>
          <div className={`${className}__footer__options`}>
            {options.map((option, idx) => (
              <Option {...option} key={idx} />
            ))}
          </div>
          <Button fullWidth className={`${className}__footer__button`} onClick={handleLogout}>
            Log Out
          </Button>
        </footer>
      </div>
    </div>
  );
};
