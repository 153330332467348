import { useCallback, useContext, useEffect, useState } from 'react';
import { activityService } from '../services';
import { ActivityReqParams, UserActivitiesImpact } from '../domain';
import { AuthContext } from 'src/providers';

const fetchUserActivitiesImpact = async () => {
  const data = await activityService.getUserActivitiesImpact();

  return data;
};

export const useUserActivitiesImpact = () => {
  const [data, setData] = useState<UserActivitiesImpact | null>(null);

  const fetch = useCallback(async () => {
    const impact = await fetchUserActivitiesImpact();
    setData(impact);
  }, []);

  async function reset() {
    setData(null);
    await fetch();
  };

  useEffect(() => {
    fetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    userActivitiesImpact: data,
    reset,
  };
};
