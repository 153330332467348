const findThreeDimensionalGroupIndex = (index: number) => {
  let result;
  if ((index + 1) % 3 === 1) {
    result = 1;
  } else if ((index + 1) % 3 === 2) {
    result = 2
  } else if ((index + 1) % 3 === 0) {
    result = 3;
  }

  return result;
}

const findTwoDimensionalGroupIndex = (index: number) => {
  let result;
  if (index % 2 === 0) {
    result = 1;
  } else {
    result = 2
  }

  return result;
}

export const rearrangeThreeDimensionalArray = (array: any[], limit: number) => {
  if (limit === 1) {
    return { ['1']: array };
  }

  const findGroupIndex = {
    2: findTwoDimensionalGroupIndex,
    3: findThreeDimensionalGroupIndex
  }[limit];

  return  array
    .map((item, index) => ({ item, groupIndex: findGroupIndex(index)}))
    .reduce((acc, { item, groupIndex }) => {
      acc[`${groupIndex}`] = [...(acc[`${groupIndex}`] || []), item];
      return acc;
    }, {})
}