import React, { FC, useCallback, useState } from 'react';
import {
  BarChart as BarChartComponent,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { scalePow } from 'd3-scale';

import { ActivityType } from 'src/modules/activities/domain';
import { DesktopChartBarTooltipData } from 'src/modules/impact/domain';

import { ContentWithListSection } from '../../../tooltip-contents';

type BarItem = { name: string; value: number; order: number };

type BarChartProps = {
  data: BarItem[];
  desktopTooltipData: DesktopChartBarTooltipData;
  type: ActivityType;
  onClick: () => void;
};

const formatCategoriesTick = (props: any) => {
  const { x, y, payload } = props;
  let { value } = payload;

  let fill = '#000000';
  let fontWeight = 'normal';

  if (value === 'You') {
    fill = '#702082';
    fontWeight = 'bold';
  }

  if (value === 'Company') {
    fill = '#702082';
    fontWeight = 'bold';
    value = 'All Dentons';
  }

  return (
    <g transform={`translate(${x - 105},${y + 5})`}>
      <text fill={fill} fontWeight={fontWeight}>
        {value}
      </text>
    </g>
  );
};

const scale = scalePow().exponent(0.25);

export const BarChart: FC<BarChartProps> = ({ data, type, onClick, desktopTooltipData }) => {
  const config = {
    [ActivityType.Water]: {
      startColor: '#9FD7FF',
      endColor: '#1DA1F2',
    },
    [ActivityType.Energy]: {
      startColor: '#FFE390',
      endColor: '#F5B800',
    },
    [ActivityType.Emissions]: {
      startColor: '#96E983',
      endColor: '#43B02A',
    },
    // [ActivityType.Inclusion]: {
    //   startColor: '#e783e9',
    //   endColor: '#6e276f',
    // },
    // [ActivityType.Community]: {
    //   startColor: '#83c7e9',
    //   endColor: '#2a43b0',
    // },
  }[type];

  const { startColor, endColor } = config;

  const [tooltipDesktopData, setTooltipDesktopData] = useState({
    title: '',
    sentences: [],
  });

  const handleMouseOver = useCallback(
    (barItem) => {
      const {
        payload: { name, value: impactValue },
      } = barItem;
      let location = name;

      if (name === 'You') {
        location = 'Your';
      }

      const measureUnit = {
        [ActivityType.Water]: 'l/year',
        [ActivityType.Energy]: 'kWh/year',
        [ActivityType.Emissions]: 'kg CO2e/year',
        // [ActivityType.Inclusion]: 'inclusion/year',
        // [ActivityType.Community]: 'community/year',
      }[type];

      const title = `${location} impact equals: ${impactValue} ${measureUnit}`;

      setTooltipDesktopData({
        title,
        sentences: desktopTooltipData[name.toLowerCase()].sentences,
      });
    },
    [desktopTooltipData],
  );

  const handleMouseLeave = useCallback(() => {
    setTooltipDesktopData({
      title: '',
      sentences: [],
    });
  }, []);

  const formatNumbersTick = useCallback((tickItem: number) => {
    return Math.round(tickItem).toLocaleString();
  }, []);

  const gradientId = `impact-bar-gradient-${type}`;

  const gradientColor = () => {
    return (
      <linearGradient id={gradientId} x1="0" y1="0" x2="1" y2="0">
        <stop offset="0%" stopColor={startColor} stopOpacity={1} />
        <stop offset="100%" stopColor={endColor} stopOpacity={1} />
      </linearGradient>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChartComponent data={data} layout="vertical" onClick={onClick}>
        <defs>{gradientColor()}</defs>
        <CartesianGrid strokeDasharray="5 5" horizontal={false} />
        <XAxis
          type="number"
          domain={[0, 'dataMax']}
          minTickGap={10}
          ticks={data.map((item) => item.value)}
          tickFormatter={formatNumbersTick}
          scale={scale}
        />
        <YAxis type="category" dataKey="name" width={115} tick={formatCategoriesTick} />
        <Tooltip
          wrapperStyle={{ top: -200, left: 0, visibility: tooltipDesktopData.sentences.length ? 'visible' : 'hidden' }}
          content={<ContentWithListSection title={tooltipDesktopData.title} sentences={tooltipDesktopData.sentences} />}
        />
        <Bar
          minPointSize={5}
          dataKey="value"
          fill={`url(#${gradientId})`}
          height={10}
          radius={4}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        />
      </BarChartComponent>
    </ResponsiveContainer>
  );
};
