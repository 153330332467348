import React, { useCallback, useContext } from 'react';

import { MobileActivitiesFiltersContext } from 'src/providers';
import { useViewport } from 'src/hooks';
import { Close } from 'src/assets/icons';
import { Button } from 'src/components';
import { MobileSingleSelect } from 'src/components/UI/MobileSelect';

import { difficultyOptions, impactOptions, locationOptions, typeOptions } from '../../helpers';

import './MobileFilters.scss';

const className = 'mobile-filters'

const FilterLabel = ({ children }) => <div className={`${className}__label`}>{children}</div>
const HorizontalLine = () => <div className={`${className}__horizontal-line`} />

export const MobileFilters: React.FC = () => {
  const { isOpen, toggleFilters, filters, updateFilters, setFilters } = useContext(MobileActivitiesFiltersContext)
  const { isDesktop } = useViewport();

  const onClose = useCallback(() => {
    toggleFilters();
  }, [toggleFilters]);

  const onResetFilters = useCallback(() => {
    setFilters({});
  }, [setFilters]);

  if (!isOpen || isDesktop) {
    return null;
  }

  return (
    <div className={className}>
      <div className={`${className}__body`}>
        <div className={`${className}__header`}>
          <h3>Filters</h3>
          <Button className={`${className}__reset-filters`} fullWidth={false} onClick={onResetFilters}>Reset all filters</Button>
          <Button className={`${className}__close`} fullWidth={false} onClick={onClose}><Close /></Button>
        </div>
        <div className={`${className}__filters`}>
          <FilterLabel>Type</FilterLabel>
          <MobileSingleSelect
            value={filters.type}
            options={typeOptions}
            onChange={(type) => updateFilters({ type })} />
          <HorizontalLine />
          <FilterLabel>Difficulty</FilterLabel>
          <MobileSingleSelect
            value={filters.difficulty}
            options={difficultyOptions}
            onChange={(difficulty) => updateFilters({ difficulty })} />
          <HorizontalLine />
          <FilterLabel>Impact</FilterLabel>
          <MobileSingleSelect
            value={filters.impact}
            options={impactOptions}
            onChange={(impact) => updateFilters({ impact })} />
          <HorizontalLine />
          <FilterLabel>Location</FilterLabel>
          <MobileSingleSelect
            value={filters.location}
            options={locationOptions}
            onChange={(location) => updateFilters({ location })} />
        </div>
      </div>
    </div>
  );
};
