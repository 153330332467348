import { useEffect, useCallback, useReducer } from 'react';
import { AxiosResponse } from 'axios';

type Error = any;

type State<TResponse extends object> = {
  loading: boolean | null;
  data: TResponse | null;
  error: Error | null;
};

const initialState = {
  loading: null,
  data: null,
  error: null,
};

type ActionType = 'loading' | 'success' | 'failure';
type Action<TResponse extends object> = {
  type: ActionType;
  payload?: TResponse | Error;
};

type Reducer<TResponse extends object> = (state: State<TResponse>, action: Action<TResponse>) => State<TResponse>;

const dataReducer = <TResponse extends object>(
  state: State<TResponse>,
  action: Action<TResponse>,
): State<TResponse> => {
  switch (action.type) {
    case 'loading':
      return { ...state, loading: true };
    case 'success':
      return { ...state, loading: false, data: action.payload as TResponse };
    case 'failure':
      return { ...state, loading: false, error: action.payload as Error };
    default:
      return state;
  }
};

const defaultOptions = {
  // whether hook should automatically fetch data initially
  // and refetch whenever "method" changes
  autoFetch: true,
};

export function useFetch<TResponse extends object = any>(
  method: () => Promise<AxiosResponse<TResponse>>,
  options = defaultOptions,
): [State<TResponse>, () => Promise<() => void>] {
  const [state, dispatch] = useReducer<Reducer<TResponse>>(dataReducer, initialState);

  const refetch = useCallback(async () => {
    dispatch({ type: 'loading' });
    try {
      const res = await method();
      dispatch({ type: 'success', payload: res?.data ?? null });
    } catch (err) {
      dispatch({ type: 'failure', payload: err });
    }
    // TODO: return function to cancel request using cancel token
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [method]);

  useEffect(() => {
    if (options.autoFetch) {
      refetch();
    }
  }, [method]); // eslint-disable-line react-hooks/exhaustive-deps

  return [state, refetch];
};