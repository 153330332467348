import React, { useCallback, useContext } from 'react';
import { ActivityType } from 'src/modules/activities/domain';
import { MobileChartBarImpactProviderContext } from 'src/providers';

import { DashboardData, ChartBarTooltipDataList } from '../../domain';
import { BarChart, ChartContainer } from './components';

import './Dashboard.scss';

type DashboardProps = {
  data: DashboardData;
};

const className = 'dashboard';

export const Dashboard: React.FC<DashboardProps> = ({ data }) => {
  const [_, updateConfig] = useContext(MobileChartBarImpactProviderContext);

  const onBarChartClick = useCallback(
    (tooltipData: ChartBarTooltipDataList) => {
      updateConfig({ data: tooltipData, isOpen: true });
    },
    [updateConfig],
  );

  return (
    <div className={className}>
      <div className={`${className}__top-charts`}>
        <ChartContainer title="Water impact">
          <BarChart
            data={data.Water.chartData}
            desktopTooltipData={data.Water.tooltipData.desktop}
            type={ActivityType.Water}
            onClick={() => onBarChartClick(data.Water.tooltipData.mobile)}
          />
        </ChartContainer>
        <ChartContainer title="Energy impact">
          <BarChart
            data={data.Energy.chartData}
            desktopTooltipData={data.Energy.tooltipData.desktop}
            type={ActivityType.Energy}
            onClick={() => onBarChartClick(data.Energy.tooltipData.mobile)}
          />
        </ChartContainer>
      </div>
      <div className={`${className}__bottom-chart`}>
        <ChartContainer title="Emissions impact">
          <BarChart
            data={data.Emissions.chartData}
            desktopTooltipData={data.Emissions.tooltipData.desktop}
            type={ActivityType.Emissions}
            onClick={() => onBarChartClick(data.Emissions.tooltipData.mobile)}
          />
        </ChartContainer>
      </div>
      {/* <div className={`${className}__bottom-chart`}>
        <ChartContainer title="Inclusion impact">
          <BarChart
            data={data.Inclusion.chartData}
            desktopTooltipData={data.Inclusion.tooltipData.desktop}
            type={ActivityType.Inclusion}
            onClick={() => onBarChartClick(data.Inclusion.tooltipData.mobile)}
          />
        </ChartContainer>
      </div>
      <div className={`${className}__bottom-chart`}>
        <ChartContainer title="Community impact">
          <BarChart
            data={data.Community.chartData}
            desktopTooltipData={data.Community.tooltipData.desktop}
            type={ActivityType.Community}
            onClick={() => onBarChartClick(data.Community.tooltipData.mobile)}
          />
        </ChartContainer>
      </div> */}
    </div>
  );
};
