export const debounce = <A = unknown, R = void>(
  fn: (args: A) => R,
  ms: number,
): [(args: A) => Promise<R>, () => void] => {
  let timer;

  const debouncedFunc = (args?: A): Promise<R> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        resolve(fn(args));
      }, ms);
    });

  const teardown = () => clearTimeout(timer);

  return [debouncedFunc, teardown];
};

export const debounceAsync = (func: (...args: any) => Promise<any>, wait: number) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    const promiseForFunc = new Promise((resolve) => {
      timer = setTimeout(resolve, wait);
    });

    return promiseForFunc.then(() => func(...args));
  };
};
