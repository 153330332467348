import React from "react";
import { ActivityType } from "src/modules/activities/domain";
import { Medal, MedalsMinValues } from "../../domain";
import { ImpactProgressBar } from "./ImpactProgressBar";

import './ImpactProgressBars.scss';

const className = 'impact-progress-bars';

type Impact = {
  type: ActivityType;
  userImpactValue: number;
  maxValue: number;
  medalsMinValues: MedalsMinValues;
  tooltipData: string[];
};

type ImpactProgressBarsProps = {
  userActivitiesTotalCount: number;
  impacts: Impact[],
};

export const ImpactProgressBars: React.FC<ImpactProgressBarsProps> = ({ userActivitiesTotalCount = 0, impacts }) => {
  const progressBars = impacts.map(({ type, userImpactValue = 0, medalsMinValues, tooltipData }) => {
    const maxValue = medalsMinValues.gold;
    const percent = Math.floor(userImpactValue * (100 / maxValue));

    const bronzeMedalPosition = Math.floor(medalsMinValues.bronze * (100 / maxValue));
    const silverMedalPosition = Math.floor(medalsMinValues.silver * (100 / maxValue));
    const goldenMedalPosition = Math.floor(medalsMinValues.gold * (100 / maxValue)) - 3;

    let medalAchived;
    let unitsLeftToNextLevel = 0;

    if (userImpactValue < medalsMinValues.bronze) {
      medalAchived = null;
      unitsLeftToNextLevel = medalsMinValues.bronze - userImpactValue;
    }

    if (userImpactValue < medalsMinValues.silver && userImpactValue >= medalsMinValues.bronze) {
      medalAchived = Medal.bronze;
      unitsLeftToNextLevel = medalsMinValues.silver - userImpactValue;
    }

    if (userImpactValue >= medalsMinValues.silver && userImpactValue < medalsMinValues.gold) {
      medalAchived = Medal.silver;
      unitsLeftToNextLevel = medalsMinValues.gold - userImpactValue;
    }

    if (userImpactValue >= medalsMinValues.gold) {
      medalAchived = Medal.gold;
      unitsLeftToNextLevel = null;
    }

    return {
      type,
      reductionNumber: userImpactValue,
      percent,
      stepPositionsInPercents: [bronzeMedalPosition, silverMedalPosition, goldenMedalPosition],
      medalAchived,
      tooltipData,
      unitsLeftToNextLevel,
    }
  });

  return <div className={className}>
    <div className={`${className}__header`}>Your total activities: {userActivitiesTotalCount}</div>
    <div className={`${className}__container`}>
      {progressBars.map((bar, idx) => <ImpactProgressBar {...bar} key={idx} />)}
    </div>
  </div>
}