import React from 'react';
import classNames from 'classnames';

import './index.scss';

// TODO: refactor type into enum
export const Button = ({
  className = '',
  addon = false,
  fullWidth = true,
  bordered = false,
  uppercase = false,
  large = false,
  medium = false,
  round = false,
  tertiary = false,
  type = 'button',
  children,
  ...rest
}: any) => {
  return (
    <button
      className={classNames('button', className, {
        'button--fullWidth': fullWidth,
        'button--bordered': bordered,
        'button--uppercase': uppercase,
        'button--large': large,
        'button--medium': medium,
        'button--addon': addon,
        'button--round': round,
        'button--tertiary': tertiary,
      })}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
};
