import paths from './paths';

export const links =
[{ to: paths.activities, label: 'Activities'}, { to: paths.impact, label: 'Impact'}, { to: paths.report, label: 'Report'}];

export const adminRoutes = [paths.report];

const linksToExludeOnMobile = [paths.report, paths.reportOffices];

export const mobileLinks = links.filter((link) => !linksToExludeOnMobile.includes(link.to));
