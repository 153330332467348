import { useCallback, useEffect, useState } from 'react';

import { activityService } from 'src/modules/activities/services';

import { ActivityImpactInUnderstandableUnits } from '../domain';

const fetchUserActivitiesImpactInUnderstandableUnits = async () => {
  const data = await activityService.getUserActivitiesImpactInUnderstandableUnits()

  return data;
};

export const useUserActivitiesImpactInUnderstandableUnits = () => {
  const [data, setData] = useState<ActivityImpactInUnderstandableUnits[] | null>(null);

  const fetch = useCallback(async () => {
    const impactInUnderstandableUnits = await fetchUserActivitiesImpactInUnderstandableUnits();
    setData(impactInUnderstandableUnits);
  }, []);

  async function reset() {
    setData(null);
    await fetch();
  };

  useEffect(() => {
    fetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const userActivitiesImpactInUnderstandableUnitsPerType = useMemo(() => data.reduce(()), [data]);

  return {
    userActivitiesImpactInUnderstandableUnits: data,
    reset,
  };
};
