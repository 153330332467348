import React, { useMemo, useState } from 'react';

import { EmptyTableState, LoadableTableBody, TableInfiniteScroll } from "src/components"
import { ActivitiesTab } from '../../consts';
import { IActivity } from "../../domain";
import { ActivityCard } from "../ActivityCard";
import ScrollContainer from 'src/components/ScrollContainer';
import { rearrangeThreeDimensionalArray } from 'src/helpers';
import { useViewport } from 'src/hooks';

import './ActivitiesTable.scss';

interface ActivitiesTableProps {
  loading: boolean;
  data: Partial<IActivity>[];
  loaded: boolean;
  onLoaderReached: () => Promise<unknown>;
  tableTab: ActivitiesTab;
  onActivityAction: (activityId: number, onActivityAction: boolean) => void;
}

export const ActivitiesTable: React.FC<ActivitiesTableProps> =
  ({ loading, data, loaded, tableTab, onLoaderReached, onActivityAction  }) => {
  const { isLarge, isXxxl } = useViewport();

  const columnLimit = useMemo(() => {
    if (isXxxl) return 3;
    if (isLarge) return 2;
    return 1;
  }, [isLarge, isXxxl]);

  const result = useMemo(() => rearrangeThreeDimensionalArray(data, columnLimit), [data, columnLimit]);

  const [expandedActivityId, setExpandedActivityId] = useState<number | null>(null);

  return <ScrollContainer className="activities-table">
    <LoadableTableBody
      className="activities-table__body"
      loading={loading}
      noResult={!data?.length}
      customEmptyState={<EmptyTableState colSpan={1} icon={<></>} title="No activities" />}
    >
      {Object.keys(result).map((key, index) => {
        return result[key]?.length &&
        <div className="activities-table__column" key={index}>{result[key].map((activity, index) => {
          return (
            <ActivityCard
              key={index}
              activity={activity}
              isMy={tableTab === ActivitiesTab.My}
              isExpanded={expandedActivityId === activity.activityId}
              onActivityAction={onActivityAction}
              onActivityExpand={setExpandedActivityId}
            />
          );
      })} </div>})}
    </LoadableTableBody>
    <TableInfiniteScroll initialRequest loading={loading} onLoaderReached={onLoaderReached} loaded={loaded} />
  </ScrollContainer>;
}