import React, { ComponentProps, FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const combineComponents = (...components: FC<Props>[]): FC<Props> => {
  return components.reduce(
    (AccumulatedComponents: React.FC<Props>, CurrentComponent: React.FC<Props>) => {
      return ({ children }: ComponentProps<FC<Props>>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>,
  );
};