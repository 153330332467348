import React, { useState, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IActivity } from 'src/modules/activities/domain';
import { difficultyOptions, impactOptions, locationOptions, typeOptions } from '../../domain/helpers';
import { newActivityNotes } from '../../consts';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
  SelectChangeEvent,
  Dialog,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
} from '@mui/material';
import { ILocation } from 'src/types';

interface FormValues {
  positiveAction: string;
  description: string;
  notes: string;
  sources: string;
  locations: number[] | string;
  difficulty: string;
  impact: string;
  isWater: boolean;
  isEnergy: boolean;
  isWaste: boolean;
  isEmissions: boolean;
  isInclusion: boolean;
  isCommunity: boolean;
  waterReduction: number | string;
  energyReduction: number | string;
  wasteReduction: number | string;
  tcO2eReduction: number | string;
}

class DefaultForm {
  positiveAction = '';
  description = '';
  notes = '';
  sources = '';
  locations = [];
  difficulty = '';
  impact = '';
  isWater = false;
  isEnergy = false;
  isWaste = false;
  isEmissions = false;
  isInclusion = false;
  isCommunity = false;
  waterReduction: number | string = '';
  energyReduction: number | string = '';
  wasteReduction: number | string = '';
  tcO2eReduction: number | string = '';

  constructor(data?: IActivity) {
    this.positiveAction = data?.positiveAction || '';
    this.description = data?.description || '';
    this.notes = data?.notes || '';
    this.sources = data?.sources || '';
    this.difficulty = data?.difficulty || '';
    this.impact = data?.impact || '';
    this.isWater = data?.isWater || false;
    this.isEnergy = data?.isEnergy || false;
    this.isWaste = data?.isWaste || false;
    this.isEmissions = data?.isEmissions || false;
    this.isInclusion = data?.isInclusion || false;
    this.isCommunity = data?.isCommunity || false;
    this.waterReduction = data?.waterReduction || '';
    this.energyReduction = data?.energyReduction || '';
    this.wasteReduction = data?.wasteReduction || '';
    this.tcO2eReduction = data?.tcO2eReduction || '';
    this.setLocations(data?.locations);
  }

  setLocations(dataLocations: ILocation[]) {
    if (!dataLocations) {
      return;
    }

    this.locations = dataLocations.map((location) => location.locationId);
  }
}

export function ActivitiesDialog({ open, locationsList, handleClose, onSave, formData }) {
  //   const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({ ...new DefaultForm() });
  const [waterEmission, setWaterEmission] = useState(0); // kg CO2e for water
  const [energyEmission, setEnergyEmission] = useState(0); // kg CO2e for energy
  const [totalEmissions, setTotalEmissions] = useState(0); // total kg CO2e

  useEffect(() => {
    setFormValues({ ...new DefaultForm(formData) });
  }, [formData]);

  useEffect(() => {
    calculateEmissions();
  }, [formValues.waterReduction, formValues.energyReduction, formValues.tcO2eReduction]);

  const [errors, setErrors] = useState<Partial<FormValues>>({
    positiveAction: '',
    description: '',
    notes: '',
    sources: '',
    locations: '',
    difficulty: '',
    impact: '',
    waterReduction: '',
    energyReduction: '',
    wasteReduction: '',
    tcO2eReduction: '',
  });

  const calculateEmissions = () => {
    const waterEmission = calculateWaterEmission(formValues.waterReduction);
    const energyEmission = calculateEnergyEmission(formValues.energyReduction);
    const totalEmissions = calculateTotalEmission(formValues.tcO2eReduction, waterEmission, energyEmission);

    setWaterEmission(waterEmission);
    setEnergyEmission(energyEmission);
    setTotalEmissions(totalEmissions);
  };

  const calculateWaterEmission = (liters) => {
    return liters * 0.00022;
  };

  const calculateEnergyEmission = (kWh) => {
    return kWh * 0.000371;
  };

  const calculateTotalEmission = (CO2e, waterEmission, energyEmission) => {
    return parseFloat(CO2e) + waterEmission + energyEmission;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name as string]: value as string,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name as string]: '',
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  };

  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  };

  const handleSave = (event) => {
    event.preventDefault();

    const newErrors: FormValues = {} as FormValues;
    if (!formValues.positiveAction) {
      newErrors.positiveAction = 'Positive Action is required';
    }
    if (!formValues.description) {
      newErrors.description = 'Description is required';
    }
    if (!formValues.sources) {
      newErrors.sources = 'Sources is required';
    }
    if (!formValues.locations.length) {
      newErrors.locations = 'Location is required';
    }
    if (!formValues.difficulty) {
      newErrors.difficulty = 'Difficulty is required';
    }
    if (!formValues.impact) {
      newErrors.impact = 'Impact is required';
    }
    if (formValues.isWater && !formValues.waterReduction) {
      newErrors.waterReduction = 'Reduction is required when Water Type is selected';
    }
    if (formValues.isEnergy && !formValues.energyReduction) {
      newErrors.energyReduction = 'Reduction is required when Energy Type is selected';
    }

    if (formValues.isWaste && !formValues.wasteReduction) {
      newErrors.wasteReduction = 'Reduction is required when Waste Type is selected';
    }

    if (formValues.isEmissions && !formValues.tcO2eReduction) {
      newErrors.tcO2eReduction = 'Reduction is required when Emission Type is selected';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Submit form logic
      console.log('Form submitted successfully');
      const updatedData = { ...formData, ...formValues };
      updatedData.locations = updatedData.locations.map((locationId) =>
        locationsList.find((item) => item.locationId === locationId),
      );
      onSave?.(updatedData);
      setFormValues({ ...new DefaultForm() });
      handleClose();
    }
  };

  const handleNo = (event) => {
    event.preventDefault();
    handleClose?.();
  };

  const locations = locationsList.map((location: ILocation) => {
    return (
      <MenuItem key={location.locationId} value={location.locationId}>
        {location.name}
      </MenuItem>
    );
  });

  const difficultyList = difficultyOptions.map((difficulty) => {
    return (
      <MenuItem key={difficulty.value} value={difficulty.value}>
        {difficulty.label}
      </MenuItem>
    );
  });

  const impactList = impactOptions.map((impact) => {
    return (
      <MenuItem key={impact.value} value={impact.value}>
        {impact.label}
      </MenuItem>
    );
  });

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'New activity'}</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              sx={{ mb: 1 }}
              name="positiveAction"
              label="Title of Positive Action"
              variant="standard"
              fullWidth
              value={formValues.positiveAction}
              onChange={handleChange}
              error={!!errors.positiveAction}
              helperText={errors.positiveAction}
            />
            <TextField
              sx={{ mb: 1 }}
              name="description"
              label="Short description of Positive Action"
              variant="standard"
              fullWidth
              value={formValues.description}
              onChange={handleChange}
              error={!!errors.description}
              helperText={errors.description}
            />
            <TextField
              sx={{ mb: 1 }}
              name="notes"
              label="Notes (admin only, these don’t appear on the site)"
              variant="standard"
              fullWidth
              value={formValues.notes}
              onChange={handleChange}
            />
            <TextField
              sx={{ mb: 1 }}
              name="sources"
              label="Sources (where did the data come from, include URLs if possible)"
              variant="standard"
              fullWidth
              value={formValues.sources}
              onChange={handleChange}
              error={!!errors.sources}
              helperText={errors.sources}
            />
            <FormControl sx={{ mb: 1 }} fullWidth variant="standard" error={!!errors.locations}>
              <InputLabel>Locations</InputLabel>
              <Select
                name="locations"
                value={formValues.locations}
                multiple
                onChange={handleLocationChange}
                label="Locations"
              >
                {locations}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 1 }} fullWidth variant="standard" error={!!errors.difficulty}>
              <InputLabel>Difficulty</InputLabel>
              <Select name="difficulty" value={formValues.difficulty} onChange={handleSelectChange} label="Difficulty">
                {difficultyList}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 1 }} fullWidth variant="standard" error={!!errors.impact}>
              <InputLabel>Impact</InputLabel>
              <Select name="impact" value={formValues.impact} onChange={handleSelectChange} label="Impact">
                {impactList}
              </Select>
            </FormControl>
            <FormGroup sx={{ flexDirection: 'row', alignItems: 'center', mb: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ paddingBottom: '0' }}
                    checked={formValues.isWater}
                    onChange={handleCheckboxChange}
                    name="isWater"
                  />
                }
                label="Water"
                sx={{ alignItems: 'end', width: '50%', marginRight: '0' }}
              />
              {formValues.isWater && (
                <TextField
                  name="waterReduction"
                  label="Reduction in litres per year"
                  variant="standard"
                  value={formValues.waterReduction}
                  onChange={handleChange}
                  error={!!errors.waterReduction}
                  helperText={
                    errors.waterReduction
                      ? errors.waterReduction
                      : waterEmission
                      ? `Water Emission Reduction: ${waterEmission.toFixed(3)} kg CO2e`
                      : ''
                  }
                  sx={{ width: '50%' }}
                />
              )}
            </FormGroup>
            <FormGroup sx={{ flexDirection: 'row', alignItems: 'center', mb: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ paddingBottom: '0' }}
                    checked={formValues.isEnergy}
                    onChange={handleCheckboxChange}
                    name="isEnergy"
                  />
                }
                label="Electricity"
                sx={{ alignItems: 'end', width: '50%', marginRight: '0' }}
              />
              {formValues.isEnergy && (
                <TextField
                  name="energyReduction"
                  label="Reduction in kWh per year"
                  variant="standard"
                  value={formValues.energyReduction}
                  onChange={handleChange}
                  error={!!errors.energyReduction}
                  helperText={
                    errors.energyReduction
                      ? errors.energyReduction
                      : energyEmission
                      ? `Energy Emission Reduction: ${energyEmission.toFixed(3)} kg CO2e`
                      : ''
                  }
                  sx={{ width: '50%' }}
                />
              )}
            </FormGroup>
            <FormGroup sx={{ flexDirection: 'row', alignItems: 'center', mb: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ paddingBottom: '0' }}
                    checked={formValues.isWaste}
                    onChange={handleCheckboxChange}
                    name="isWaste"
                  />
                }
                label="Waste"
                sx={{ alignItems: 'end', width: '50%', marginRight: '0' }}
              />
              {formValues.isWaste && (
                <TextField
                  name="wasteReduction"
                  label="Reduction – scale 1-5 (largest)"
                  variant="standard"
                  value={formValues.wasteReduction}
                  onChange={handleChange}
                  error={!!errors.wasteReduction}
                  helperText={errors.wasteReduction}
                  sx={{ width: '50%' }}
                />
              )}
            </FormGroup>
            <FormGroup sx={{ flexDirection: 'row', alignItems: 'center', mb: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ paddingBottom: '0' }}
                    checked={formValues.isEmissions}
                    onChange={handleCheckboxChange}
                    name="isEmissions"
                  />
                }
                label="Emission"
                sx={{ alignItems: 'end', width: '50%', marginRight: '0' }}
              />
              {formValues.isEmissions && (
                <TextField
                  name="tcO2eReduction"
                  label="Reduction in Kg of CO2e per year"
                  variant="standard"
                  value={formValues.tcO2eReduction}
                  onChange={handleChange}
                  error={!!errors.tcO2eReduction}
                  helperText={
                    errors.tcO2eReduction
                      ? errors.tcO2eReduction
                      : totalEmissions
                      ? `Total Emissions: ${totalEmissions.toFixed(3)} kg CO2e`
                      : ''
                  }
                  sx={{ width: '50%' }}
                />
              )}
            </FormGroup>
            <FormGroup sx={{ flexDirection: 'row', alignItems: 'center', mb: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ paddingBottom: '0' }}
                    checked={formValues.isInclusion}
                    onChange={handleCheckboxChange}
                    name="isInclusion"
                  />
                }
                label="Inclusion"
                sx={{ alignItems: 'end', width: '50%', marginRight: '0' }}
              />
            </FormGroup>
            <FormGroup sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ paddingBottom: '0' }}
                    checked={formValues.isCommunity}
                    onChange={handleCheckboxChange}
                    name="isCommunity"
                  />
                }
                label="Community"
                sx={{ alignItems: 'end', width: '50%', marginRight: '0' }}
              />
            </FormGroup>
          </form>

          <Typography sx={{ pt: 2 }}>
            <Box sx={{ color: 'text.secondary' }}>Notes for adding an activity:</Box>
          </Typography>
          <List dense sx={{ listStyle: 'decimal', pl: 4, color: 'text.secondary', typography: 'body1' }}>
            {newActivityNotes.map((note, i) => (
              <ListItem key={`note-${i}`} sx={{ display: 'list-item' }}>
                <ListItemText secondary={note} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo} color="error" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleSave} color="success" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
