import React, { useCallback, useContext } from 'react';

import { MobileChartBarImpactProviderContext } from 'src/providers';
import { useViewport } from 'src/hooks';
import { Button } from 'src/components';
import { Close } from 'src/assets/icons';

import { ContentWithListSection } from '../../../tooltip-contents';

import './MobileChartBarImpact.scss';

const className = 'mobile-chart-bar-impact';

export const MobileChartBarImpact: React.FC = () => {
  const [config, updateConfig] = useContext(MobileChartBarImpactProviderContext);
  const { data } = config;

  const onClose = useCallback(() => {
    updateConfig({ isOpen: !config.isOpen });
  }, [config.isOpen, updateConfig]);

  const { isDesktop } = useViewport();

  const { isOpen } = config;

  if (!isOpen || isDesktop) {
    return null;
  }

  return (
    <div className={className}>
      <div className={`${className}__body`}>
        <div className={`${className}__header`}>
          <Button className={`${className}__close`} fullWidth={false} onClick={onClose}><Close /></Button>
        </div>
        <div className={`${className}__content`}>
          {data.map(({ title, sentences }, idx) =>
            <ContentWithListSection key={idx} title={title} sentences={sentences} />
          )}
        </div>
      </div>
    </div>
  );
};
