import classNames from 'classnames';
import React, { useMemo, useRef, useState } from 'react';

import { uiLabels } from '../labels';

import './index.scss';
import { Button } from '../Button';
import { useOnClickOutside } from 'src/hooks';
import { Close, DropdownArrowDown, DropdownArrowUp } from 'src/assets/icons';

const remove = (el, arr) => arr.filter((x) => x !== el);
const add = (el, arr) => [...arr, el];
const toggle = (el, arr) => (arr.includes(el) ? remove(el, arr) : add(el, arr));

const isOptionSelected = (optionValue, value, isMulti) => {
  if (isMulti) return value.includes(optionValue);
  return optionValue === value;
};

const Select = ({
  options,
  value,
  onChange,
  isMulti = false,
  selectAllOption = false,
  showCheckboxes = false,
  closeOnSelect = true,
  className = '',
  placeholder = '',
  clearable = false,
  disabled = false,
  error = false,
  enablePagination = false,
  onLoadMoreClick = null,
  loading = false,
  loaded = false,
  emptyMessage = uiLabels.noOptions,
}: any) => {
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setShowOptions(false));

  const optionsMap = useMemo(
    () =>
      options?.reduce((acc, option) => {
        acc[option.value] = option.label;
        return acc;
      }, {}),
    [options],
  );

  const handleOptionClick = (e, optionValue) => {
    e.stopPropagation();
    if (isMulti) {
      onChange(toggle(optionValue, value));
    } else if (value !== optionValue) {
      onChange(optionValue);
    }

    if (closeOnSelect) setShowOptions(false);
  };

  const handleValueRemove = (optionValue) => {
    onChange(remove(optionValue, value));
  };

  const handleClear = () => {
    onChange(isMulti ? [] : null);
  };

  const handleSelectAllClick = (e) => {
    e.stopPropagation();
    if (value.length === options.length) {
      onChange([]);
    } else {
      onChange(options.map(({ value }) => value));
    }
    if (closeOnSelect) setShowOptions(false);
  };

  const hasValue = (!isMulti && !!value) || (isMulti && !!value.length);

  return (
    <div
      ref={dropdownRef}
      className={classNames(className, 'select', {
        'select--disabled': disabled,
      })}
      onClick={() => setShowOptions(true)}
    >
      <div
        className={classNames('select__control', {
          'select__control--open': showOptions,
          'select__control--error': error,
        })}
      >
        <div className="select__value-container">
          {!hasValue && <div className="select__placeholder">{placeholder}</div>}

          {isMulti &&
            (selectAllOption && value.length && value.length === options.length ? (
              <div className="select__value">
                <span className="select__value__label">All Selected</span>
              </div>
            ) : (
              value.map((v) => (
                <div key={v} className="select__multi-value">
                  <span className="select__multi-value__label">{optionsMap[v]}</span>
                  <span className="select__multi-value__remove" onClick={() => handleValueRemove(v)}>
                    <Close />
                  </span>
                </div>
              ))
            ))}

          {!isMulti && hasValue && (
            <div className="select__value">
              <span className="select__value__label">{optionsMap[value]}</span>
            </div>
          )}
        </div>
        {hasValue && clearable && (
          <div className="select__clear" onClick={handleClear}>
            <Close />
          </div>
        )}
        <div
          className="select__indicator"
          // onClick={() => setShowMenu(!showMenu)}
        >
          {showOptions ? <DropdownArrowUp /> : <DropdownArrowDown />}
        </div>
      </div>

      {showOptions && (
        <div className="select__options">
          {selectAllOption && isMulti && (
            <div
              className={classNames('select__option', {
                'select__option--checkbox': showCheckboxes,
                'select__option--selected': value.length === options.length,
              })}
              onClick={handleSelectAllClick}
            >
              Select / Unselect All
            </div>
          )}

          {options?.length ? (
            options.map(({ label, value: optionValue }) => (
              <div
                key={optionValue}
                className={classNames('select__option', {
                  'select__option--multi': isMulti,
                  'select__option--checkbox': showCheckboxes,
                  'select__option--selected': isOptionSelected(optionValue, value, isMulti),
                })}
                onClick={(e) => handleOptionClick(e, optionValue)}
              >
                {label}
              </div>
            ))
          ) : (
            <div className="select__option select__option--empty">{emptyMessage}</div>
          )}

          {enablePagination && !loaded && (
            <Button className="load-more-btn" secondary fullWidth onClick={onLoadMoreClick} disabled={loading}>
              load more
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Select;

export const SingleSelect = (props) => <Select {...props} />;
export const MultiSelect = (props) => <Select isMulti closeOnSelect={false} clearable {...props} />;
