import React, { createContext } from 'react';
import { Auth, useAuth } from 'src/hooks';

export const AuthContext = createContext<Auth>({} as Auth);

const AuthContextProvider = ({ children }) => {
  const auth = useAuth();

  return (
    <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
  );
};
export default AuthContextProvider;
