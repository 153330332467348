import classNames from 'classnames';
import { FC, MouseEventHandler, ReactNode } from 'react';

import './TableRow.scss';

interface ITableRow {
  className?: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLTableRowElement>;
}

const TableRow: FC<ITableRow> = ({ onClick, children, className }) => (
  <tr className={classNames(className, 'table-row')} onClick={onClick}>
    {children}
  </tr>
);

export default TableRow;
