import { IReportItem } from 'src/modules/report/domain';
import { BaseService } from 'src/services/base-service';
import { RequestQueryParams } from 'src/types';
import { ActivitiesResponse, ActivityList, ActivityReqParams, UserActivitiesImpact } from '../domain';

class UsersService extends BaseService {
  constructor() {
    super({ basePath: 'Users' });
  }

  async list(options: Partial<ActivityReqParams> & Omit<RequestQueryParams, 'sortOrder'>) {
    const params = this.getQueryParams(options);
    const response = await this.api.get<{ data: ActivityList }>(`/get-filtered`, { params });

    return response;
  }

  async getUsers() {
    const response = await this.api.get<ActivitiesResponse>(`/get-filtered`);
    return response.data;
  }
}

export const usersService = new UsersService();
