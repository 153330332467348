import React, { ReactNode, useMemo } from 'react';
import classNames from 'classnames';

import './TableHead.scss';

type TableHeadProps = {
  columns: TableColumn[];
  tableId: string;
  fixed?: boolean;
};

export type TableColumn = {
  key?: string;
  label: ReactNode | string;
  width?: string;
};

export type TableColumns = TableColumn[];

export default ({ columns = [], tableId = '', fixed = false }: TableHeadProps) =>
  useMemo(() => {
    const getColumnKey = (key) => `${tableId}-table-header-${key}`;

    const getColumnClasses = (key) => classNames('table-header-cell', `table-header-cell__${tableId}--${key}`);

    const getColumnStyle = (width) => ({
      width: width || 'auto',
    });

    return (
      <thead className={classNames('table-header', { 'table-header--fixed': fixed })}>
        <tr>
          {columns.map((column, index) => (
            <th
              key={getColumnKey(column.key || index)}
              className={getColumnClasses(column.key || index)}
              style={getColumnStyle(column.width)}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
    );
  }, [columns, tableId, fixed]);
