import React from 'react';
import classNames from 'classnames';

import './Footer.scss';

export const Footer: React.FC = () => {
  return (
    <footer className={classNames('footer')} />
  );
};
