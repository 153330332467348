import { useEffect } from 'react';

import { debounce } from 'src/helpers';

export const useDebounce = <A = unknown, R = void>(fn: (args: A) => R, ms: number): ((args?: A) => Promise<R>) => {
  const [debouncedFunc, teardown] = debounce<A, R>(fn, ms);

  useEffect(() => () => teardown(), [teardown]);

  return debouncedFunc;
};
