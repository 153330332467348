import { useCallback, useEffect, useState } from 'react';

import { activityService } from 'src/modules/activities/services';

import { CompanyHierarchyLocationsWithEmployeeCount } from '../domain';

const fetchCompanyLocationsWithEmployeeCount = async () => {
  const data = await activityService.getCompanyHierarchyLocations()

  return data;
};

export const useCompanyLocationsWithEmployeeCount = () => {
  const [data, setData] = useState<CompanyHierarchyLocationsWithEmployeeCount | null>(null);

  const fetch = useCallback(async () => {
    const impact = await fetchCompanyLocationsWithEmployeeCount();
    setData(impact);
  }, []);

  async function reset() {
    setData(null);
    await fetch();
  };

  useEffect(() => {
    fetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    companyLocationsWithEmployeeCount: data,
    reset,
  };
};
