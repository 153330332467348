const activities = 'activities';
const impact = 'impact';
const report = 'report';
const administration = 'administration';

export default {
  activities: `/${activities}`,
  impact: `/${impact}`,
  report: `/${report}`,
  reportOffices: `/${report}/:region/offices`,
  faq: '/faq',
  administration: `/${administration}`,
};
