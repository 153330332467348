import React, { createContext, useState } from 'react';

export const MobileNavScreenContext = createContext<[MobileNavScreenContextValue,
  (value: Partial<MobileNavScreenContextValue>) => void,
  (value: MobileNavScreenContextValue) => void
]>([
  {} as MobileNavScreenContextValue,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

export interface MobileNavScreenContextValue {
  isOpen: boolean;
}

const MobileNavScreenContextProvider = ({ children }) => {
  const [config, setConfig] = useState<MobileNavScreenContextValue>({
    isOpen: false,
  });

  const updateConfig = (update: Partial<MobileNavScreenContextValue>) => setConfig((prev) => ({ ...prev, ...update }));

  return (
    <MobileNavScreenContext.Provider value={[config, updateConfig, setConfig]}>{children}</MobileNavScreenContext.Provider>
  );
};
export default MobileNavScreenContextProvider;
