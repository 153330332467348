import React, { createContext, useContext, useMemo, useState } from 'react';
import { useQueryParams, useToggle } from 'src/hooks';
import { ActivityReqParams, IActivity } from 'src/modules/activities/domain';
import { AuthContext } from './AuthContextProvider';

export const MobileActivitiesFiltersContext = createContext<{
  isOpen: boolean;
  toggleFilters: () => void;
  filters: Partial<ActivityReqParams>;
  setFilters: (filters: Partial<ActivityReqParams>) => void;
  updateFilters: (filters: Partial<ActivityReqParams>) => void;
  activities: IActivity[];
  setActivities: React.Dispatch<React.SetStateAction<IActivity[]>>;
  loaded: boolean;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  email: string;
  reset: () => void;
}>({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleFilters: () => {},
  filters: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFilters: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateFilters: () => {},
  activities: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActivities: () => {},
  loaded: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoaded: () => {},
  offset: 1,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOffset: () => {},
  email: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  reset: () => {},
});

const MobileActivitiesFiltersProvider = ({ children }) => {
  const { authState } = useContext(AuthContext);
  const email = useMemo(() => authState?.username, [authState?.username]);
  const [offset, setOffset] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [activities, setActivities] = useState<IActivity[]>([]);

  const {
    params: filters,
    setQueryParams,
    // resetQueryParams: resetFilters,
  } = useQueryParams<ActivityReqParams>(`[${authState?.localAccountId}]-activities-filters`, (q) => q);

  const [isOpen, toggleFilters] = useToggle(false);

  const setFilters = (update: Partial<ActivityReqParams>) => setQueryParams({ ...update });

  const updateFilters = (update: Partial<ActivityReqParams>) => {
    setQueryParams({ ...filters, ...update });
    reset();
  };

  const reset = () => {
    setOffset(1);
    setLoaded(false);
    setActivities([]);
  };

  return (
    <MobileActivitiesFiltersContext.Provider
      value={{
        isOpen,
        toggleFilters,
        filters,
        setFilters,
        updateFilters,
        email,
        offset,
        setOffset,
        loaded,
        setLoaded,
        reset,
        activities,
        setActivities,
      }}
    >
      {children}
    </MobileActivitiesFiltersContext.Provider>
  );
};
export default MobileActivitiesFiltersProvider;
