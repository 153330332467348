import { useRef, useEffect } from 'react';

const defaultOptions = {
  rootMargin: '0px',
};

export const useIntersectionObserver = (
  element: HTMLElement | null,
  callback: (...args: unknown[]) => unknown,
  // eslint-disable-next-line no-undef
  options: IntersectionObserverInit = defaultOptions,
): IntersectionObserver => {
  const instanceRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (typeof IntersectionObserver !== 'undefined' && element !== null) {
      const observer = new IntersectionObserver(([element]) => {
        callback(element);
      }, options);

      observer.observe(element);
      instanceRef.current = observer;
    } else {
      instanceRef.current = null;
    }

    return () => {
      if (instanceRef.current) {
        instanceRef.current.disconnect();
      }
    };
  }, [callback, element, options]);

  return instanceRef.current;
};
