import React from "react";

import './ImpactPageSection.scss';

type ImpactPageSectionProps = {
  title: string;
  description?: string;
  children: React.ReactNode;
}

const className = 'impact-page-section';

export const ImpactPageSection: React.FC<ImpactPageSectionProps> = ({ title, description, children }) => {
  return <div className={className}>
    <div className={`${className}__title`}>{title}</div>
    { description && <div className={`${className}__description`}>{description}</div> }
    <div>{children}</div>
  </div>
};
