import { FC, ReactNode } from 'react';
import { EmptyState } from 'src/components/EmptyState';

import './EmptyTableState.scss';

interface EmptyTableStateProps {
  colSpan: number;
  icon: ReactNode;
  title: string;
}

export const EmptyTableState: FC<EmptyTableStateProps> = ({ colSpan, icon, title }) => {
  return (
    <tr>
      <td colSpan={colSpan} className="empty-table-row">
        <EmptyState icon={icon} title={title} />
      </td>
    </tr>
  );
};
