import React from 'react';
import { Ellipsis, EllipsisColor } from '../Ellipsis';

export type EllipsesInRowProps = {
  color: EllipsisColor;
  filledQuantity: number;
}

export const EllipsesInRow: React.FC<EllipsesInRowProps> = ({ color, filledQuantity }) => {
  return <>{new Array(filledQuantity).fill(null).map((_, idx) =>
    <Ellipsis key={`${idx}-${color}`} color={color} filled />)}
  </>
}
