import React, { useCallback } from 'react';
import classNames from 'classnames';

import { useConfirm } from 'src/hooks';
import { Close } from 'src/assets/icons';
import { Button } from '../UI';

import './ConfirmationModal.scss';

export const ConfirmationModal: React.FC = () => {
  const {
    message,
    confirmButtonLabel,
    closeButtonLabel,
    icon,
    hasFooterDivider,
    buttonLayout,
    closeOnOverlayClick,
    isOpen,
    onConfirm,
    onSecondaryButtonClick,
    onClose,
    isTitleCenteredVertical,
    fullWidthButton,
  } = useConfirm();

  const onOverlayClick = (e) => {
    if (closeOnOverlayClick && e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSecondaryButtonClick = useCallback(() => {
    onSecondaryButtonClick?.();
    onClose();
  }, [onClose, onSecondaryButtonClick]);

  const isVerticalLayout = buttonLayout === 'vertical';
  const isHorizontalSpaceBetween = buttonLayout === 'horizontal-space-between';

  if (!isOpen) {
    return null;
  }

  return (
    <div className="confirmation-modal" onClick={onOverlayClick}>
      <div className="confirmation-modal__body">
        <div onClick={onClose} className="confirmation-modal__close">
          <Close />
        </div>
        {icon && <div className="confirmation-modal__icon">{icon}</div>}
        <div
          className={classNames('confirmation-modal__title', {
            'vertical-center': isTitleCenteredVertical,
          })}
        >
          {message}
        </div>
        <div
          className={classNames('confirmation-modal__footer', {
            bordered: hasFooterDivider,
            vertical: isVerticalLayout,
            'horizontal-space-between': isHorizontalSpaceBetween,
          })}
        >
          <Button
            className={classNames('confirmation-modal__footer-btn', {
              'confirmation-modal__footer-btn--full-width': fullWidthButton,
            })}
            kind="secondary"
            onClick={handleSecondaryButtonClick}
          >
            {closeButtonLabel}
          </Button>
          <Button
            className={classNames('confirmation-modal__footer-btn', {
              'confirmation-modal__footer-btn--full-width': fullWidthButton,
            })}
            type="submit"
            kind="primary"
            onClick={onConfirm}
          >
            {confirmButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};
