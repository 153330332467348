import { ILocation } from 'src/types';
import { ActivityDifficulty, ActivityImpact, ActivityLocation, ActivityType } from '../domain';

export const typeOptions = Object.values(ActivityType).map((type) => ({
  value: type,
  label: type,
}));

export const difficultyOptions = Object.values(ActivityDifficulty).map((difficult) => ({
  value: difficult,
  label: difficult,
}));

export const impactOptions = Object.values(ActivityImpact).map((impact) => ({
  value: impact,
  label: impact,
}));

export const locationOptions = (locations) =>
  locations.map((location: ILocation) => ({
    value: location.name,
    label: location.name,
  }));
