import React, { useCallback, useContext, useRef } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import { ContactUs, FAQ, LoginLogoutRed, Suggestions } from 'src/assets/icons';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { useOnClickOutside } from 'src/hooks';
import paths from 'src/routes/paths';

import './UserDropdown.scss';
import { AuthContext } from 'src/providers';

const userDropdownClassName = 'user-dropdown';

type Option = {
  name: string;
  Icon: () => JSX.Element;
  className?: string;
  onClick?: () => void;
  href?: string;
  target?: string;
};

const Option: React.FC<Option> = ({ Icon, name, className, onClick, href, target }) => (
  <a
    href={href}
    target={target}
    className={classNames(`${userDropdownClassName}__option`, className)}
    onClick={onClick}
  >
    <div className={`${userDropdownClassName}__option__icon`}>
      <Icon />
    </div>
    <div className={`${userDropdownClassName}__option__name`}>{name}</div>
  </a>
);

type UserDropdown = {
  className: string;
  toggleDropdown: () => void;
};

export const UserDropdown: React.FC<UserDropdown> = ({ className, toggleDropdown }) => {
  const { fullname, authState, logout: handleLogout, isAdmin } = useContext(AuthContext);
  const history = useHistory();

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => toggleDropdown());

  const handleFAQ = useCallback(() => {
    history.push(paths.faq);
    toggleDropdown();
  }, [history, toggleDropdown]);

  const handleAdministration = useCallback(() => {
    history.push(paths.administration);
    toggleDropdown();
  }, [history, toggleDropdown]);

  if (!authState) {
    return null;
  }

  const options: Option[] = [
    {
      name: 'Contact Us',
      Icon: ContactUs,
      href: 'mailto:PositiveImpacts@dentons.com?subject=A Million Positive Impacts | Contact us',
      target: '_blank',
    },
    {
      name: 'Your Suggestions',
      Icon: Suggestions,
      href: 'mailto:PositiveImpacts@dentons.com?subject=A Million Positive Impacts | Content Suggestions',
      target: '_blank',
    },
    { name: 'FAQ', Icon: FAQ, onClick: handleFAQ },
    {
      name: 'Log Out',
      Icon: LoginLogoutRed,
      className: `${userDropdownClassName}__option--red`,
      onClick: handleLogout,
    },
  ];

  if (isAdmin) {
    options.unshift({
      name: 'Administration',
      Icon: () => <AdminPanelSettingsOutlinedIcon />,
      onClick: handleAdministration,
    });
  }

  return (
    <div className={classNames(userDropdownClassName, className)} ref={dropdownRef}>
      <div className={`${userDropdownClassName}__fullname`}>{fullname}</div>
      <div className={`${userDropdownClassName}__email`}>{authState?.username}</div>
      <div className={`${userDropdownClassName}__line`} />
      <div className={`${userDropdownClassName}__options`}>
        {options.map((option, idx) => (
          <Option {...option} key={idx} />
        ))}
      </div>
    </div>
  );
};
