import { ActivityType } from "src/modules/activities/domain";

export enum Medal {
  bronze = 'bronze',
  silver = 'silver',
  gold = 'gold'
}

export type MedalsMinValues = {
  [Medal.bronze]: number;
  [Medal.silver]: number;
  [Medal.gold]: number;
}

export type ImpactMedalsLevel = {
  maxValue: number;
  medalsMinValues: MedalsMinValues;
}

export type ImpactMedalsLevelPerType = Record<ActivityType, ImpactMedalsLevel>