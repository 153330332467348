import React from 'react';
import { ContentContainer } from '../ContentContainer';

import './ContentWithListSection.scss';

const className = 'content-with-list-section';

type ContentWithListSectionProps = {
  sentences: string[]
  title?: string;
}

export const ContentWithListSection: React.FC<ContentWithListSectionProps> = ({
  title = "Your impact equals",
  sentences
}) => {
  return <ContentContainer title={title}>
    <div className={className}>
      <ul>
        {sentences.map((sentence, idx) => <li key={idx}>{sentence}</li>)}
      </ul>
    </div>
  </ContentContainer>
}