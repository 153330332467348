// this is basic file for storing labels from UI folder for future i10n
// TODO: think of better approach and format

export const uiLabels = {
  noSearchResult: {
    descriptionHasSearchQuery: 'Please try searching with another term',
    descriptionHasFilters: 'Please try searching with another filters',
    titleNoSearchQuery: 'Sorry we could not find any matches',
    titleHasSearchQuery: 'Sorry we could not find any matches for ',
  },
  noOptions: 'No options',
};
