import React from 'react';
import classNames from 'classnames';

import './TableCell.scss';

interface ITableCell {
  className?: string;
  title?: string;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}

const TableRow: React.FC<ITableCell> = ({ children, className, title, onClick }) => (
  <td title={title} className={classNames(className, 'table-cell')} onClick={onClick}>
    {children}
  </td>
);

export default TableRow;
