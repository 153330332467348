import Spinner from '../../UI/Spinner';

export default () => {
  return (
    <tr>
      <td colSpan={100} height="150">
        <Spinner />
      </td>
    </tr>
  );
};
