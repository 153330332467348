import React, { useMemo } from 'react';
import classNames from 'classnames';

import { ActivityType } from 'src/modules/activities/domain';
import { EmissionsIcon, WasteIcon, ElectricityIcon, WaterIcon, InclusionIcon, CommunityIcon } from 'src/assets/icons';

import './Impact.scss';

export type ImpactCardProps = {
  type: ActivityType;
  reductionNumber: number;
};

const className = 'impact-card';

export const ImpactCard: React.FC<ImpactCardProps> = ({ type, reductionNumber }) => {
  const cardConfig = {
    [ActivityType.Water]: {
      style: 'waterType',
      Icon: WaterIcon,
      unit: 'litres/year',
    },
    [ActivityType.Energy]: {
      style: 'energyType',
      Icon: ElectricityIcon,
      unit: 'kWh/year',
    },
    [ActivityType.Waste]: {
      style: 'wasteType',
      Icon: WasteIcon,
      unit: 'Level',
    },
    [ActivityType.Emissions]: {
      style: 'emissionType',
      Icon: EmissionsIcon,
      unit: 'kg CO2e/year',
    },
    // [ActivityType.Inclusion]: {
    //   style: 'inclusionType',
    //   Icon: InclusionIcon,
    //   unit: 'Level',
    // },
    // [ActivityType.Community]: {
    //   style: 'communityType',
    //   Icon: CommunityIcon,
    //   unit: 'Level',
    // },
  }[type];

  const { style, Icon, unit } = cardConfig;

  const Metrics = useMemo(() => {
    const finalReductionNumber = Math.round(reductionNumber).toLocaleString();
    let result = (
      <>
        <div>{finalReductionNumber}</div>
        <div>{unit}</div>
      </>
    );

    if (type === ActivityType.Waste) {
      result = (
        <>
          <div>{unit}</div>
          <div>{finalReductionNumber}</div>
        </>
      );
    }

    return () => result;
  }, [reductionNumber, type, unit]);

  return (
    <div className={classNames(className, `${className}__${style}`)}>
      <Icon />
      <div className={`${className}__metrics`}>
        <Metrics />
      </div>
    </div>
  );
};
