import { useState, useMemo, useEffect } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useSessionStorage, useEffectOnce } from 'usehooks-ts';

export const useQueryParams = <T,>(
  storageKey: string,
  getParamsFromQuery: (queryObject: queryString.ParsedQuery<string>) => Partial<T>,
) => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const locationSearch = search.slice(1);
  const params = useMemo(() => getParamsFromQuery(queryString.parse(search)), [search, getParamsFromQuery]);
  const paramsSearch = useMemo(() => queryString.stringify(params, { skipEmptyString: true }), [params]);
  const [savedSearch, setSavedSearch] = useSessionStorage(storageKey, locationSearch || paramsSearch);
  const isSearchRestoreNeeded = !locationSearch && !!savedSearch;
  const [isReady, setIsReady] = useState(!isSearchRestoreNeeded);

  useEffectOnce(() => {
    if (isSearchRestoreNeeded) {
      history.replace({ pathname, search: savedSearch });
      setIsReady(true);
    }
  });

  useEffect(() => {
    if (paramsSearch !== locationSearch) {
      history.replace({ pathname, search: paramsSearch });
    }
  }, [pathname, history, locationSearch, paramsSearch]);

  useEffect(() => {
    setSavedSearch(paramsSearch);
  }, [paramsSearch, setSavedSearch]);

  const setQueryParams = (newParams: Partial<T>) => {
    const newSearch = queryString.stringify(newParams, { skipEmptyString: true });

    history.push({ pathname, search: newSearch });
  };

  const resetQueryParams = () => {
    history.push({ pathname, search: '' });
  };

  return {
    isReady,
    params,
    setQueryParams,
    resetQueryParams,
    areParamsApplied: !!paramsSearch.length,
  };
};
