import React from 'react';

import { useHistory } from "react-router-dom";

import { type AuthenticationResult, type EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { CustomNavigationClient } from "./utils/NavigationClient";
import { MsalProvider } from "@azure/msal-react";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

export const Security: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  msalInstance.setNavigationClient(navigationClient);

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
}