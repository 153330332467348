import { combineComponents } from 'src/helpers';
import ConfirmContextProvider from './ConfirmContextProvider';
import MobileActivitiesFiltersProvider from './MobileActivitiesFiltersProvider';
import MobileChartBarImpactProvider from './MobileChartBarImpactProvider';
import MobileImpactProgressBarViewProvider from './MobileImpactProgressBarViewProvider';
import MobileNavScreenContextProvider from './MobileNavScreenProvider';
import DisclaimerProvider from './DisclaimerProvider';

const providers = [
  ConfirmContextProvider,
  MobileNavScreenContextProvider,
  MobileActivitiesFiltersProvider,
  MobileImpactProgressBarViewProvider,
  MobileChartBarImpactProvider,
  DisclaimerProvider,
]
export const AppContextProvider = combineComponents(...providers);