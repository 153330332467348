import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';

import { FAQ, MainPage } from '../pages';

import paths from './paths';
import { loginRequest } from 'src/auth/authConfig';
import { ActivitiesPage, ImpactPage, ReportPage, AdministrationPage } from 'src/modules';
import { AuthContext } from 'src/providers';

const Routes = () => {
  const auth = useContext(AuthContext);

  const { instance, inProgress, isAdmin } = auth;
  const isAuthReady = !!auth.authState;

  useEffect(() => {
    if (!inProgress && !isAuthReady) {
      instance.loginRedirect(loginRequest);
    }
  }, [inProgress, instance, isAuthReady]);

  return (
    <>
      <MainPage>
        <React.Suspense fallback={<>Loading</>}>
          <Switch>
            <Route path={paths.activities} component={ActivitiesPage} />
            <Route path={paths.impact} component={ImpactPage} />
            <Route path={paths.administration} component={AdministrationPage} />
            {isAdmin && <Route path={paths.report} component={ReportPage} />}
            <Route path={paths.faq} component={FAQ} />
            {isAuthReady && <Redirect from="*" to={paths.activities} />}
          </Switch>
        </React.Suspense>
      </MainPage>
    </>
  );
};

export default Routes;
