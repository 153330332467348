import React, { useMemo } from 'react';
import classNames from 'classnames';

import { ActivityType } from 'src/modules/activities/domain';
import { EmissionsIcon, WasteIcon, ElectricityIcon, WaterIcon, InclusionIcon, CommunityIcon } from 'src/assets/icons';

import './ReportImpactCard.scss';

export type ReportImpactCardProps = {
  type: ActivityType;
  reductionNumber: number;
};

const className = 'report-impact-card';

export const ReportImpactCard: React.FC<ReportImpactCardProps> = ({ type, reductionNumber }) => {
  const cardConfig = {
    [ActivityType.Water]: {
      style: 'waterType',
      Icon: WaterIcon,
      unit: 'l/year',
    },
    [ActivityType.Energy]: {
      style: 'energyType',
      Icon: ElectricityIcon,
      unit: 'kWh/year',
    },
    [ActivityType.Waste]: {
      style: 'wasteType',
      Icon: WasteIcon,
      unit: 'kg/year',
    },
    [ActivityType.Emissions]: {
      style: 'emissionType',
      Icon: EmissionsIcon,
      unit: 'kg CO2e/year',
    },
    // [ActivityType.Inclusion]: {
    //   style: 'inclusionType',
    //   Icon: InclusionIcon,
    //   unit: 'inclusion/year',
    // },
    // [ActivityType.Community]: {
    //   style: 'communityType',
    //   Icon: CommunityIcon,
    //   unit: 'community/year',
    // },
  }[type];

  const { style, Icon, unit } = cardConfig;

  const metrics = useMemo(() => {
    const finalReductionNumber = Math.round(reductionNumber).toLocaleString();
    const result = `${finalReductionNumber} ${unit}`;

    return result;
  }, [reductionNumber, unit]);

  return (
    <div className={classNames(className, `${className}__${style}`)}>
      <Icon />
      <div>{metrics}</div>
    </div>
  );
};
