import { useCallback, useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import classNames from 'classnames';

import { AdministrationTab } from './consts';
import { difficultyOptions, impactOptions, locationOptions, typeOptions } from '../activities/helpers';
import { Button, SingleSelect } from 'src/components';
import { useViewport } from 'src/hooks';
import { ActivitiesList, LocationsList, UsersList } from './components';
import Typography from '@mui/material/Typography';
import { useActivities } from '../activities/hooks';
import { useAdministrationActivities, useUsers, useLocations } from './hooks';

import './AdministrationPage.scss';
import Spinner from 'src/components/UI/Spinner';
import { Filters } from 'src/assets/icons';

const className = 'administration-page';

export const AdministrationPage = () => {
  const [activeTab, setActiveTab] = useState<AdministrationTab>(AdministrationTab.Activities);
  const {
    activities,
    loading,
    loaded,
    loadActivities,
    filters,
    updateFilters,
    setFilters,
    reset,
    toggleFilters: toggleMobileFilters,
    removeActivity,
  } = useActivities();

  const {
    isActivitiesLoading,
    activitiesList,
    addActivity,
    updateActivity,
    deleteActivity,
    hideActivity,
    unhideActivity,
    fetch,
  } = useAdministrationActivities(filters);
  const { isLocationsLoading, locationsList, addLocation, deleteLocation } = useLocations();
  // const { isUsersLoading, usersList } = useUsers();

  const { isDesktop } = useViewport();

  const onNavigateButtonClick = useCallback(
    (key) => {
      setActiveTab(AdministrationTab[key]);
      reset();
    },
    [activeTab, reset],
  );

  const onFiltersIconClick = useCallback(() => {
    toggleMobileFilters();
  }, [toggleMobileFilters]);

  const onResetFiltersClick = useCallback(() => {
    setFilters({});
    reset();
  }, [reset, setFilters]);

  const handleFilterUpdate = useCallback(
    (newFilters) => {
      updateFilters(newFilters); // Оновлення фільтрів
      fetch(newFilters); // Виклик функції fetch з новими фільтрами
    },
    [updateFilters, fetch],
  );

  const ActivitiesFilter = () => (
    <div className={`${className}__table-header`}>
      <SingleSelect
        className={`${className}__select`}
        name="type"
        placeholder="Choose type"
        options={typeOptions}
        value={filters.type}
        clearable
        onChange={(type) => handleFilterUpdate({ ...filters, type })}
      />
      <SingleSelect
        className={`${className}__select`}
        name="difficulty"
        placeholder="Choose difficulty"
        options={difficultyOptions}
        value={filters.difficulty}
        clearable
        onChange={(difficulty) => handleFilterUpdate({ ...filters, difficulty })}
      />
      <SingleSelect
        className={`${className}__select`}
        name="impact"
        placeholder="Choose impact"
        options={impactOptions}
        value={filters.impact}
        clearable
        onChange={(impact) => handleFilterUpdate({ ...filters, impact })}
      />
      <SingleSelect
        className={`${className}__select`}
        name="location"
        placeholder="Choose location"
        options={locationOptions(locationsList)}
        value={filters.location}
        clearable
        onChange={(location) => handleFilterUpdate({ ...filters, location })}
      />
      <Button onClick={onResetFiltersClick} fullWidth={false} className={`${className}__reset-filters`}>
        Reset all filters
      </Button>
    </div>
  );

  const tabs = [
    { activityTabKey: AdministrationTab.Activities, text: 'Activities' },
    { activityTabKey: AdministrationTab.Locations, text: 'Locations' },
    // { activityTabKey: AdministrationTab.Users, text: 'Users' },
  ];

  const AdministrationTabs = () => (
    <div className={`${className}__tabs`}>
      <div className={`${className}__tabs__navs`}>
        {tabs.map(({ activityTabKey, text }, index) => (
          <div
            key={index}
            className={classNames(`${className}__tabs__navs__nav`, {
              [`${className}__tabs__navs__nav--active`]: activeTab === activityTabKey,
            })}
            onClick={() => onNavigateButtonClick(text)}
          >
            {text}
          </div>
        ))}
      </div>
      {!isDesktop ? (
        <Button
          onClick={onFiltersIconClick}
          fullWidth={false}
          className={`${className}__table-header-mobile__filters-icon`}
        >
          <Filters />
        </Button>
      ) : (
        ''
      )}
    </div>
  );

  const tabConfig = {
    [AdministrationTab.Activities]: {
      filterComponent: <ActivitiesFilter />,
      listComponent: (listData, actions, locationsList) => (
        <ActivitiesList
          listData={listData}
          locationsList={locationsList}
          handleAdd={actions.add}
          handleUpdate={actions.update}
          handleDelete={actions.delete}
          handleHide={actions.hide}
          handleUnhide={actions.unhide}
        />
      ),
      noDataText: 'No activities..',
      isLoading: isActivitiesLoading,
      listData: activitiesList,
      locationsList,
      actions: {
        add: addActivity,
        update: updateActivity,
        delete: deleteActivity,
        hide: hideActivity,
        unhide: unhideActivity,
      },
    },
    [AdministrationTab.Locations]: {
      filterComponent: null,
      listComponent: (listData, actions) => (
        <LocationsList listData={listData} handleAdd={actions.add} handleDelete={actions.delete} />
      ),
      noDataText: 'No locations..',
      isLoading: isLocationsLoading,
      listData: locationsList,
      actions: {
        add: addLocation,
        delete: deleteLocation,
      },
    },
    // [AdministrationTab.Users]: {
    //   filterComponent: null,
    //   listComponent: (listData) => <UsersList listData={listData} />,
    //   noDataText: 'No Users..',
    //   isLoading: isUsersLoading,
    //   listData: usersList,
    //   actions: {},
    // },
  };

  useEffect(() => {
    fetch(filters);
  }, [filters]);

  return (
    <div className={className}>
      <div>
        <AdministrationTabs />
        {isDesktop ? tabConfig[activeTab].filterComponent : ''}
        {tabConfig[activeTab].isLoading ? (
          <Spinner />
        ) : tabConfig[activeTab].listData.length > 0 ? (
          tabConfig[activeTab].listComponent(
            tabConfig[activeTab].listData,
            tabConfig[activeTab].actions,
            tabConfig[activeTab]?.locationsList,
          )
        ) : (
          <Typography>{tabConfig[activeTab].noDataText}</Typography>
        )}
      </div>
    </div>
  );
};
