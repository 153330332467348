/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { forwardRef, memo, useImperativeHandle, useState } from 'react';
import { Close, Search as SearchIcon } from 'src/assets/icons';

import './index.scss';

const SearchInput = forwardRef<{ clearHandler: () => void }, any>(
  (
    {
      className = '',
      onSubmit = (value?: any) => {},
      onChange,
      borderRadius = undefined,
      placeholder = 'Search',
      minCharacters = null,
      initialValue = '',
      ...rest
    },
    ref,
  ) => {
    const [searchInput, setSearchInput] = useState(initialValue);
    const [timer, setTimer] = useState<any>();

    const onChangeHandler = (e) => {
      clearTimeout(timer);
      setSearchInput(e.target.value || '');
      onChange?.(e.target.value);
      setTimer(
        setTimeout(() => {
          onSubmit(e.target.value);
        }, 1000),
      );
    };

    const onKeyDownHandler = (event) => {
      if (event.key === 'Enter') {
        onSubmit(searchInput);
      }
    };

    const clearHandler = () => {
      setSearchInput('');
      onChange?.('');
      onSubmit('');
    };

    useImperativeHandle(ref, () => ({
      clearHandler,
    }));

    return (
      <span className={classNames('search-input', className)}>
        <input
          ref={ref}
          type="text"
          className="search-input__field"
          name="search-input"
          value={searchInput}
          onChange={onChangeHandler.bind(this)}
          placeholder={placeholder || 'Search'}
          onKeyDown={onKeyDownHandler.bind(this)}
          style={{ borderRadius }}
          autoComplete="off"
          {...rest}
        />

        <span onClick={onSubmit.bind(this, searchInput)} className="search-input__icon">
          <SearchIcon />
        </span>
        {minCharacters && searchInput.length > 0 && searchInput.length < minCharacters ? (
          <span className="search-input__min-required">
            Please enter at least <strong>{minCharacters}</strong> characters to search
          </span>
        ) : null}
        {searchInput && (
          <span className="search-input__icon close" onClick={clearHandler}>
            <Close />
          </span>
        )}
      </span>
    );
  },
);

export default memo(SearchInput);
