import {
  ActivityImpactInUnderstandableUnits,
  CompanyHierarchyLocationsWithEmployeeCount,
} from 'src/modules/impact/domain';
import { IReportItem } from 'src/modules/report/domain';
import { BaseService } from 'src/services/base-service';
import { RequestQueryParams } from 'src/types';
import {
  ActivityList,
  ActivityReqParams,
  SubscribeActivity,
  UnsubscribeActivity,
  UserActivitiesImpact,
} from '../domain';

class ActivityService extends BaseService {
  constructor() {
    super({ basePath: 'UserActivities' });
  }

  async list(options: Partial<ActivityReqParams> & Omit<RequestQueryParams, 'sortOrder'>) {
    const params = this.getQueryParams(options);
    const response = await this.api.get<{ data: ActivityList }>(`/get-filtered`, { params });
    return response;
  }

  async subscribe(data: SubscribeActivity) {
    await this.api.post(`/add`, data);
  }

  async unsubscribe(data: UnsubscribeActivity) {
    await this.api.post(`/remove`, data);
  }

  async getUserActivitiesImpact() {
    const response = await this.api.get<UserActivitiesImpact>(`/user-activities-impact`);

    return response.data;
  }

  async getCompanyHierarchyLocations() {
    const response = await this.api.get<CompanyHierarchyLocationsWithEmployeeCount>(
      `/company-hierarchy-levels-employee-numbers-for-user`,
    );

    return response.data;
  }

  async getUserActivitiesImpactInUnderstandableUnits() {
    const response = await this.api.get<ActivityImpactInUnderstandableUnits[]>(
      `/user-activities-impact-in-understandable-units`,
    );

    return response.data;
  }

  async getReport() {
    const response = await this.api.get<IReportItem[]>(`/company-hierarchy-infos`);

    return response.data;
  }

  async getCSVReport() {
    const { data } = await this.api.get('/impact-report', { responseType: 'blob' });

    return data;
  }
}

export const activityService = new ActivityService();
