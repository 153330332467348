import classNames from 'classnames';
import React, { FC, ReactNode, ReactPortal, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './Tooltip.module.scss';

export enum Position {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
  leftTop = 'leftTop',
  leftBottom = 'leftBottom',
  rightTop = 'rightTop',
  rightBottom = 'rightBottom',
}

// export type Position = 'left' | 'right' | 'top' | 'bottom' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom';
// export type Position = modo Position;
type Coordinates = { x: number; y: number };
type CoordinatesSet = { [position in Position]: Coordinates };

export interface TooltipProps {
  content: ReactNode;
  children?: ReactNode;
  className?: string;
  position?: Position;
  fullWidth?: boolean;
}

export const Tooltip: FC<TooltipProps> = ({
  className,
  content,
  children,
  position = 'left',
  fullWidth = false,
}) => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const [visible, setVisible] = useState(false);
  const [pos, setPos] = useState<Coordinates>({ x: 0, y: 0 });

  const portalChild = useRef(document.createElement('div'));

  useEffect(() => {
    const mount = document.getElementById('portal-root');
    const popup = portalChild.current;

    mount?.appendChild(popup);

    return () => {
      mount?.removeChild(popup);
    };
  }, []);

  const setPosition = useCallback(
    (rect: DOMRect) => {
      const tooltipRect = tooltipRef.current?.getBoundingClientRect();
      const arrow = 0;

      const centralizeArrowToLeftInPercents = 15;
      const centralizeArrowToRightInPercents = 75;
      const arrowEps = 3;

      const coordinates: CoordinatesSet = {
        left: {
          x: rect.left - ((tooltipRect?.width ?? 0) + arrow),
          y: rect.top + rect.height / 2 - (tooltipRect?.height ?? 0) / 2,
        },
        right: {
          x: rect.left + (rect.width + arrow),
          y: rect.top + rect.height / 2 - (tooltipRect?.height ?? 0) / 2,
        },
        top: {
          x: rect.left + rect.width / 2 - (tooltipRect?.width ?? 0) / 2,
          y: rect.top - arrow - (tooltipRect?.height ?? 0),
        },
        bottom: {
          x: rect.left + rect.width / 2 - (tooltipRect?.width ?? 0) / 2,
          y: rect.top + rect.height + arrow,
        },
        leftTop: {
          x:
            rect.left +
            rect.width / 2 -
            ((tooltipRect?.width ?? 0) * (centralizeArrowToLeftInPercents + arrowEps)) / 100,
          y: rect.top - arrow - (tooltipRect?.height ?? 0),
        },
        leftBottom: {
          x:
            rect.left +
            rect.width / 2 -
            ((tooltipRect?.width ?? 0) * (centralizeArrowToLeftInPercents + arrowEps)) / 100,
          y: rect.top + rect.height + arrow,
        },
        rightTop: {
          x:
            rect.left +
            rect.width / 2 -
            ((tooltipRect?.width ?? 0) * (centralizeArrowToRightInPercents + arrowEps)) / 100,
          y: rect.top - arrow - (tooltipRect?.height ?? 0),
        },
        rightBottom: {
          x:
            rect.left +
            rect.width / 2 -
            ((tooltipRect?.width ?? 0) * (centralizeArrowToRightInPercents + arrowEps)) / 100,
          y: rect.top + rect.height + arrow,
        },
      };

      const newPos = coordinates[position];

      const x = newPos.x + window.scrollX;
      const y = newPos.y + window.scrollY;

      setPos({ x, y });
    },
    [position],
  );

  const onHover = useCallback(
    (e: React.MouseEvent | React.FocusEvent) => {
      const rect = e.currentTarget.getBoundingClientRect();

      setPosition(rect);
      setVisible(true);
    },
    [setPosition],
  );

  const onLeave = useCallback(() => {
    setVisible(false);
  }, []);

  const TooltipLayout = useMemo(() => {
    return (
      <div
        ref={tooltipRef}
        className={classNames(styles.tooltip, styles[position], {
          [styles.isVisible]: visible,
        })}
        style={{ top: pos.y, left: pos.x }}
      >
        {content}
      </div>
    );
  }, [content, pos.x, pos.y, position, visible]);

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.fullWidth]: fullWidth,
      })}
      onMouseEnter={onHover}
      onFocus={onHover}
      onMouseLeave={onLeave}
      onBlur={onLeave}
    >
      {children}
      {createPortal ? createPortal(TooltipLayout, portalChild.current) : TooltipLayout}
    </div>
  );
};
