import React, { useMemo } from 'react';
import { BronzeMedal, GoldMedal, SilverMedal } from 'src/assets/icons';
import { ActivityType } from 'src/modules/activities/domain';
import { Medal } from 'src/modules/impact/domain';
import { ContentContainer } from '../ContentContainer';

import './ContentWithMedalSection.scss';

const className = 'content-with-medal-section';

type ContentWithMedalSectionProps = {
  medalAchived: Medal | null;
  unitsLeftToNextLevel: number | null;
  activityType: ActivityType;
};

export const ContentWithMedalSection: React.FC<ContentWithMedalSectionProps> = ({
  medalAchived,
  unitsLeftToNextLevel,
  activityType,
}) => {
  const nextLevel = useMemo(() => {
    if (medalAchived === null) {
      return Medal.bronze;
    }

    if (medalAchived === Medal.bronze) {
      return Medal.silver;
    }

    if (medalAchived === Medal.silver) {
      return Medal.gold;
    }

    if (medalAchived === Medal.gold) {
      return null;
    }
  }, [medalAchived]);

  const unit = {
    [ActivityType.Water]: 'l/year',
    [ActivityType.Energy]: 'kWh/year',
    [ActivityType.Waste]: 'level(s)',
    [ActivityType.Emissions]: 'kg CO2e/year',
    // [ActivityType.Inclusion]: 'inclusion/year',
    // [ActivityType.Community]: 'community/year',
  }[activityType];

  const suggestion = useMemo(() => {
    if (activityType === ActivityType.Waste) {
      if (!medalAchived) {
        return 'Commit to more activities to reach Bronze';
      }

      if (medalAchived === Medal.bronze) {
        return 'Commit to more activities to reach Silver';
      }

      if (medalAchived === Medal.silver) {
        return 'Commit to more activities to reach Gold';
      }

      if (medalAchived === Medal.gold) {
        return 'CONGRATULATIONS';
      }
    }

    if (unitsLeftToNextLevel && nextLevel) {
      return `Save ${Math.round(unitsLeftToNextLevel).toLocaleString()} ${unit} to reach ${nextLevel} level`;
    } else {
      return 'Congratulations, keep going!';
    }
  }, [activityType, medalAchived, nextLevel, unit, unitsLeftToNextLevel]);

  const MedalComponent = {
    [Medal.bronze]: BronzeMedal,
    [Medal.silver]: SilverMedal,
    [Medal.gold]: GoldMedal,
  }[medalAchived];

  return (
    <ContentContainer title={medalAchived ? `You achived ${medalAchived} level!` : ''}>
      <div className={className}>
        <div className={`${className}__medal`}>{MedalComponent && <MedalComponent />}</div>
        <div className={`${className}__suggestion`}>{suggestion}</div>
      </div>
    </ContentContainer>
  );
};
