import React, { FC } from 'react';

import './ChartContainer.scss';

type ChartContainer = {
  title: string;
  children: React.ReactNode;
};

export const ChartContainer: FC<ChartContainer> = ({ title, children }) => {
  return (
    <div className="chart-container">
      <h1 className="chart-container__title">{title}</h1>
      <div className="chart-container__chart">{children}</div>
    </div>
  );
};
