import React from 'react';
import { ActivityType } from 'src/modules/activities/domain';
import { Medal } from 'src/modules/impact/domain';
import { ContentWithListSection } from '../ContentWithListSection';
import { ContentWithMedalSection } from '../ContentWithMedalSection';

import './ContentInProgressBar.scss';

const className = 'content-in-progress-bar';

type ContentInProgressBarProps = {
  sentences: string[];
  medalAchived: Medal | null;
  unitsLeftToNextLevel: number | null;
  activityType: ActivityType;
}

export const ContentInProgressBar: React.FC<ContentInProgressBarProps> =
    ({ sentences, medalAchived, unitsLeftToNextLevel, activityType }) => {
  return <div className={className}>
    { activityType !== ActivityType.Waste && <><ContentWithListSection sentences={sentences} /><div className={`${className}__line`} /></>}
    <ContentWithMedalSection medalAchived={medalAchived} unitsLeftToNextLevel={unitsLeftToNextLevel} activityType={activityType} />
  </div>
}