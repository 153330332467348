import { useState, useEffect, useMemo } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1700,
};

export function useViewport() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const modes = useMemo(() => ({
    isMobile: windowDimensions.width < breakpoints.md,
    isTablet: windowDimensions.width < breakpoints.lg && windowDimensions.width >= breakpoints.md,
    isDesktop: windowDimensions.width >= breakpoints.lg,
    isLarge: windowDimensions.width <= breakpoints.xxxl && windowDimensions.width > breakpoints.lg,
    isXxxl: windowDimensions.width >= breakpoints.xxxl,
    isXl: windowDimensions.width <= breakpoints.xxxl && windowDimensions.width > breakpoints.xl,
  }), [windowDimensions]);

  return { ...windowDimensions, ...modes };
}