import React, { useMemo } from 'react';
import classNames from 'classnames';

import './TableSortableHeaderCell.scss';

interface ITableSortableHeaderCell {
  children: React.ReactNode;
  toggleSorting(column: string): void;
  column: string;
  activeColumn?: string;
  activeDirection?: string;
  className?: string;
}

const TableSortableHeaderCell: React.FC<ITableSortableHeaderCell> =
  ({ toggleSorting, children, column, activeColumn, activeDirection, className }) => {
    const arrow = useMemo(() => {
      if (activeColumn === column && activeDirection === 'asc') {
        return '▲';
      }
      if (activeColumn === column && activeDirection === 'desc') {
        return '▼';
      }
      return '▼';
    }, [activeColumn, activeDirection, column]);

    return (<div className={classNames(className, 'table-sortable-cell')}>
      <span>{children}</span>
      <button onClick={() => toggleSorting(column)} className={classNames(className, 'table-sortable-cell__button')}>
        {arrow}
      </button>
    </div>);
  };

export default TableSortableHeaderCell;
