import React, { useCallback, useContext, useRef, useState } from 'react';

import { Button } from 'src/components';
import companyLogo from 'src/assets/images/dentons-logo-filled-purple.png';

import './Disclaimer.scss';
import { DisclaimerContext } from 'src/providers';
import { useToggle } from 'usehooks-ts';

const className = 'disclaimer'

export const Disclaimer: React.FC = () => {
  const { config: { isOpen, onConfirm } } = useContext(DisclaimerContext);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const checkboxRef2 = useRef<HTMLInputElement>(null);
  const [isCheckboxChecked, toggleCheckbox] = useToggle(false);
  const [isCheckboxChecked2, toggleCheckbox2] = useToggle(false);;

  const onSubmit = useCallback(() => {
    if (checkboxRef.current.checked) {
      onConfirm(checkboxRef2.current.checked);
    }
  }, [onConfirm]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={className}>
      <div className={`${className}__body`}>
        <div className={`${className}__header`}>
          <img className={`${className}__logo`} src={companyLogo} alt="Global Sustainability Network" />
        </div>
        <div className={`${className}__main`}>
          <input ref={checkboxRef} type="checkbox" className={`${className}__checkbox`} onClick={toggleCheckbox} />
          <div>
            <p>By clicking the submit button, I hereby confirm that I have read and understood the processing of my personal
              data in line with our
              &nbsp;<a href="/privacy_notice_esg_app.pdf" target="_blank">ESG Application Privacy Notice</a>.</p>
          </div>
        </div>
        <div className={`${className}__main`}>
          <input ref={checkboxRef2} type="checkbox" className={`${className}__checkbox`} onClick={toggleCheckbox2} />
          <div>
            <p>I hereby consent to the processing of my personal data for the purpose of analysing my performance in order to
              notify me of my
              distinctive accomplishments as to the chosen commitments as well as sharing with other colleagues within Dentons as
              further described
              in the ESG Application Privacy Notice.</p>
            <p>Without your consent we won&apos;t be able to include you in these activities.</p>
            <p>Further information on how we process your personal data can be found in the
              &nbsp;<a href="/privacy_notice_esg_app.pdf" target="_blank">ESG Application Privacy Notice</a>.</p>
          </div>
        </div>
        <div className={`${className}__footer`}>
          <Button
            disabled={!isCheckboxChecked}
            fullWidth={false}
            className={`${className}__confirm-btn`}
            onClick={onSubmit}>
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};
