import React from 'react';

import './ContentContainer.scss';

const className = 'tooltip-content-container';

type ContentContainerProps = {
  title: string;
  children: React.ReactNode
}

export const ContentContainer: React.FC<ContentContainerProps> = ({ title, children }) => {
  return <div className={className}>
    <div className={`${className}__header`}>{title}</div>
    {children}
  </div>
}